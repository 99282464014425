import {useQuery} from "react-query";
import service from "../../config/service";
import {Alert, Col, Layout, Row, Spin} from "antd";
import Documents from "./Documents";
import React from "react";
import {useTranslation} from "react-i18next";
import useWindowDimensions from "../../utils/utils";
import {useDispatch} from "react-redux";
import {logoutUser} from "../../redux/actions/userActions";
import {Redirect} from "react-router";

const StatsComponent = () => {

    const {height, width} = useWindowDimensions();
    const dispatch = useDispatch();
    const {i18n, t} = useTranslation();

    const handleLocaleChange = locale => {
        // Handle locale change...

        document.getElementsByTagName("html")[0].dir = i18n.language === "ar" ? "rtl" : "ltr";
    }

    return (
        <Row
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
            style={{
                backgroundColor: "white",
                marginTop: "50px",
                marginBottom: "50px",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 0px 3px 0px #000000",
                direction: i18n.language === "ar" ? "rtl" : "ltr",
            }}
            className="mycontainer"
        >
            <Col>
                <Row style={{marginTop: 40}}>
                    <Col>
                        <img className={"img-fluid"} src="./assets/img/stats/slide1.PNG" alt="Slide 1"/>
                    </Col>
                </Row>
                <Row style={{marginTop: 60}}>
                    <Col>
                        <img className={"img-fluid"} src="./assets/img/stats/slide2.PNG" alt="Slide 2"/>
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}

const styles = (i18n) => {
    return {
        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            backgroundColor: "#1c3564",
        },
        logo: {
            width: "180px",
            borderRadius: "50%",
        },
        title: {
            marginBottom: "80px",
            textAlign: "left",
            fontFamily: "Montserrat, sans-serif",
            fontWeight: 800,
            fontSize: "2em",
            fontOpticalSizing: "auto",
            fontStyle: "normal",
            color: "#1c3564",
            marginTop: "50px",
        },
        paragraph: {
            fontFamily: "Montserrat, sans-serif",
            fontWeight: 400,
            fontSize: "1.2em",
            color: "#000000",
            textAlign: i18n.language === "ar" ? "right" : "left",
            lineHeight: "1.5em",
            direction: i18n.language === "ar" ? "rtl" : "ltr",
        },
        whiteText: {
            color: "white",
        },
        textAlignLocalization: {
            textAlign: i18n.language === "ar" ? "right" : "left",
        },
    }
}

export default StatsComponent;
