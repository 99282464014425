import React from 'react';
import {useQuery} from 'react-query';
import axios from 'axios';
import {Card, Typography, Spin, Alert, Collapse, Tag, Space, Empty, Col, Row} from 'antd';
import {CalendarOutlined, EnvironmentOutlined, ClockCircleOutlined} from '@ant-design/icons';
import {useTranslation} from "react-i18next";

const {Title, Text} = Typography;
const {Panel} = Collapse;

const CalendarEvents = () => {

    const {t, i18n} = useTranslation();

    const CALENDAR_ID = 'cdphc.events@gmail.com';
    const API_KEY = 'AIzaSyDfHtUfh1CijqmBm29wsFMBBP0oR9gFAdM';

    const getDateRange = () => {
        const now = new Date();
        const startDate = new Date(now.getFullYear(), now.getMonth(), 1);
        const endDate = new Date(now.getFullYear(), now.getMonth() + 3, 0);

        return {
            timeMin: startDate.toISOString(),
            timeMax: endDate.toISOString()
        };
    };

    const {timeMin, timeMax} = getDateRange();

    const {data: events, isLoading, isError} = useQuery(
        ["events"],
        async () => {
            const res = await axios.get(
                `https://www.googleapis.com/calendar/v3/calendars/${CALENDAR_ID}/events`,
                {
                    params: {
                        key: API_KEY,
                        timeMin: timeMin,
                        timeMax: timeMax,
                        singleEvents: true,
                        orderBy: 'startTime'
                    }
                }
            );
            return res.data.items;
        },
        {refetchOnWindowFocus: false}
    );

    // Get an array of the next three months
    const getNextThreeMonths = () => {
        const months = ['January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'];
        const result = {};
        const now = new Date();

        for (let i = 0; i < 3; i++) {
            const futureDate = new Date(now.getFullYear(), now.getMonth() + i, 1);
            const monthKey = `${months[futureDate.getMonth()]} ${futureDate.getFullYear()}`;
            result[monthKey] = [];
        }

        return result;
    };

    const groupEventsByMonth = (events) => {
        const grouped = getNextThreeMonths(); // Start with empty arrays for next 3 months

        if (!events) return grouped;

        const months = ['January', 'February', 'March', 'April', 'May', 'June',
            'July', 'August', 'September', 'October', 'November', 'December'];

        events.forEach(event => {
            const startDate = new Date(event.start.dateTime || event.start.date);
            const monthKey = `${months[startDate.getMonth()]} ${startDate.getFullYear()}`;

            if (monthKey in grouped) {
                grouped[monthKey].push(event);
            }
        });

        return grouped;
    };

    const formatEventTime = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
    };

    const formatEventDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('en-US', {
            weekday: 'short',
            month: 'short',
            day: 'numeric'
        });
    };

    if (isLoading) {
        return (
            <div style={{textAlign: 'center', padding: '50px'}}>
                <Spin size="large"/>
            </div>
        );
    }

    if (isError) {
        return (
            <Alert
                message="Error"
                description="Failed to load calendar events"
                type="error"
                showIcon
            />
        );
    }

    const panelStyle = {
        backgroundColor: '#022859',
        border: 'none',
        borderRadius: 0,
        marginBottom: 4,
    };

    const cardStyle = {
        width: '100%',
        marginBottom: 16,
        borderRadius: 8,
        boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
    };

    const groupedEvents = groupEventsByMonth(events);

    // is upcoming event or not ig
    const isUpcomingEvent = (dateString) => {
        const date = new Date(dateString);
        const now = new Date();
        return date >= now ? 'Upcoming' : 'Done';
    };

    return (
        <Collapse
            defaultActiveKey={Object.keys(groupedEvents)}
            style={{
                backgroundColor: '#022859',
                border: 'none',
                borderRadius: 8,
            }}
        >
            {Object.entries(groupedEvents).map(([month, monthEvents]) => (
                <Panel
                    header={<Title level={4} style={{margin: 0, color: '#fff'}}>{month}</Title>}
                    key={month}
                    style={panelStyle}
                >
                    {monthEvents.length > 0 ? (
                        <Row gutter={[16, 16]}>
                            {monthEvents.map(event => (
                                <Col xs={24} sm={24} md={12} lg={8} xl={8} key={event.id}>
                                    <Card
                                        size="small"
                                        style={cardStyle}
                                        hoverable
                                    >
                                        <Space direction="vertical" size="small" style={{width: '100%'}}>
                                            <Title level={4} style={{margin: 0, color: '#022859', fontSize: '16px'}}>
                                                {event.summary}
                                            </Title>

                                            <Space align="start">
                                                <CalendarOutlined style={{color: '#022859'}}/>
                                                <Text>{formatEventDate(event.start.dateTime || event.start.date)}</Text>
                                            </Space>

                                            {/*{event.start.dateTime && (*/}
                                            {/*    <Space align="start">*/}
                                            {/*        <ClockCircleOutlined style={{color: '#022859'}}/>*/}
                                            {/*        <Text>*/}
                                            {/*            {formatEventTime(event.start.dateTime)} - {formatEventTime(event.end.dateTime)}*/}
                                            {/*        </Text>*/}
                                            {/*    </Space>*/}
                                            {/*)}*/}

                                            {event.location && (
                                                <Space align="start">
                                                    <EnvironmentOutlined style={{color: '#022859'}}/>
                                                    <Text style={{flex: 1, wordBreak: 'break-word'}}>
                                                        {event.location}
                                                    </Text>
                                                </Space>
                                            )}

                                            {event.description && (
                                                <Text
                                                    type="secondary"
                                                    style={{
                                                        display: '-webkit-box',
                                                        WebkitLineClamp: 3,
                                                        WebkitBoxOrient: 'vertical',
                                                        overflow: 'hidden',
                                                        wordBreak: 'break-word'
                                                    }}
                                                >
                                                    {event.description}
                                                </Text>
                                            )}

                                            <Tag
                                                color={isUpcomingEvent(event.start.dateTime || event.start.date).toLowerCase() === 'upcoming' ? 'green' : 'lightgray'}
                                            >
                                                {isUpcomingEvent(event.start.dateTime || event.start.date)}
                                            </Tag>
                                        </Space>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    ) : (
                        <Empty
                            image={Empty.PRESENTED_IMAGE_SIMPLE}
                            description={
                                <Text style={{color: '#022859'}}>
                                    {t('views.events.noEventsAvailable')}
                                </Text>
                            }
                        />
                    )}
                </Panel>
            ))}
        </Collapse>
    );
};

export default CalendarEvents;
