import Skeletons from "../skeletons";
import {Button, Card, Col, Row, Space, Tabs, Tag} from "antd";
import MyQueueMotion from "./Home/MyQueueMotion";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {browserName, isMobile} from "react-device-detect";
import {CalendarTwoTone, CheckSquareTwoTone, ClockCircleTwoTone, LeftOutlined, RightOutlined} from "@ant-design/icons";
import Map from "./Home/Map";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faAppleWhole,
    faBanSmoking,
    faBrain,
    faChildReaching,
    faEye,
    faFlask,
    faHandHoldingMedical,
    faMagnifyingGlass,
    faPersonCane,
    faPersonRunning,
    faPills,
    faStethoscope,
    faSyringe, faXRay
} from "@fortawesome/free-solid-svg-icons";
import {faCircle} from "@fortawesome/free-regular-svg-icons";

const deg2rad = require('deg2rad')

export default ({healthCenter, isLoading}) => {

    if (isLoading || !healthCenter) {
        return <Skeletons isLoading={isLoading} healthCenterView={true}/>
    }

    const {t, i18n} = useTranslation();
    const [position, setPosition] = useState({latitude: 0, longitude: 0});

    const servicesIcon = [
        {
            key: 'general',
            icon: faStethoscope,
        },
        {
            key: 'primary_diabetes',
            icon: faCircle,
        },
        {
            key: 'primary_ncd',
            icon: faPills
        },
        {
            key: 'primary_lab',
            icon: faFlask,
        },
        {
            key: 'primary_children_vaccination',
            icon: faSyringe,
        },
        {
            key: 'primary_diatery',
            icon: faAppleWhole,
        },
        {
            key: 'primary_fundoscopy',
            icon: faEye,
        },
        {
            key: 'primary_diabetic_foot',
            icon: faMagnifyingGlass,
        },
        {
            key: 'primary_wellbaby',
            icon: faChildReaching,
        },
        {
            key: 'primary_smoking',
            icon: faBanSmoking,
        },
        {
            key: 'primary_geriatric',
            icon: faPersonCane,
        },
        {
            key: 'primary_geriatric',
            icon: faPersonCane,
        },
        {
            key: 'primary_enuresis',
            icon: faHandHoldingMedical,
        },
        {
            key: 'primary_mental',
            icon: faBrain,
        },
        {
            key: 'primary_obesity',
            icon: faPersonRunning,
        },
        {
            key: 'primary_osteoporosis',
            icon: faXRay,
        },
    ]

    useEffect(() => {

        console.log('healthCenter', healthCenter)
        if (navigator.geolocation) {
            navigator.permissions.query({name: 'geolocation'}).then(permissionStatus => {

                if (permissionStatus.state === 'denied') {
                    // alert('Please allow location access.');
                    // open app-settings:location for browser by browserName
                    // window.location.href = browserName === 'Chrome' ? 'chrome://settings/content/location' : 'app-settings:location';
                } else {
                    navigator.geolocation.getCurrentPosition(function (position) {
                        setPosition({
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                        });
                    });
                }
            });
        } else {
            console.log("Geolocation is not available in your browser.");
        }

    }, []);

    const healthCenterHoursFormat = (workingHours) => {

        if (workingHours.length === 0) return i18n.language === 'ar' ? 'لا يوجد ساعات عمل' : 'No working hours';

        if (Number(workingHours) === 7) {
            if (i18n.language === 'en' || 'en-US') return '7:00 am to 2:00 pm';
            if (i18n.language === 'ar') return '7:00 صباحاً إلى 2:00 مساءً';
        }

        if (Number(workingHours) === Number(14)) {
            if (i18n.language === 'en' || 'en-US') return '07:00 am to 9:00 pm';
            if (i18n.language === 'ar') return '07:00 صباحاً إلى 9:00 مساءً';
        }

        if (Number(workingHours) === 17) {
            if (i18n.language === 'en' || 'en-US') return '07:00 am to 12:00 am';
            if (i18n.language === 'ar') return '07:00 صباحاً إلى 12:00 صباحاً';
        }

        if (Number(workingHours) === 24) return i18n.language === 'ar' ? '24 ساعة' : '24 hours';
    }

    const healthCenterDaysFormat = (weekendDuty) => {

        if (weekendDuty === "yes") {
            return i18n.language === 'ar' ? 'طوال ايام الاسبوع' : 'All days of the week';
        } else {
            return i18n.language === 'ar' ? 'الأحد إلى الخميس' : 'Sunday to Thursday';
        }
    }

    const isOpen = (index, workingHours) => {

        const opeHours = Array.from({length: Number(workingHours)}, (v, k) => k + 1);

        // check if current time is in the range of working hours
        return opeHours.includes(new Date().getHours());
    }

    const rowStyle = () => {

        const mainStyle = {
            backgroundColor: "#f0f0f0",
            padding: "10px",
            paddingRight: "11px",
            paddingBottom: "20px",
            borderRadius: "10px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }

        if (isMobile) {
            return {
                ...mainStyle,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap"
            }
        }

        return mainStyle;
    }

    const getDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371; // Radius of the earth in km
        const dLat = deg2rad(lat2 - lat1);  // deg2rad below
        const dLon = deg2rad(lon2 - lon1);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c; // Distance in km

        // return without decimals point
        return Math.round(d);
    }

    const catchmentTabs = (healthCenter) => {
        const hasCatchment = checkCatchment(healthCenter);

        if (hasCatchment.weekDay.isAvailable && hasCatchment.weekEnd.isAvailable) {
            return (
                <Tabs
                    type="card"
                    centered
                >
                    <Tabs.TabPane tab="Week Days" key="item-1">
                        {hasCatchment.weekDay.isAvailable ? hasCatchment.weekDay.catchment.details : 'Not Available'}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Week End & Holidays" key="item-2">
                        {hasCatchment.weekEnd.isAvailable ? hasCatchment.weekEnd.catchment.details : 'Not Available'}
                    </Tabs.TabPane>
                </Tabs>
            );
        } else if (hasCatchment.weekDay.isAvailable) {
            return hasCatchment.weekDay.catchment.details;
        } else if (hasCatchment.weekEnd.isAvailable) {
            return hasCatchment.weekEnd.catchment.details;
        } else {
            return i18n.language === 'ar' ? 'لا يوجد معلومات' : 'No information';
        }
    }

    const checkCatchment = (healthCenter) => {
        let hasCatchment = {
            weekDay: {
                isAvailable: false,
                catchment: null,
            },
            weekEnd: {
                isAvailable: false,
                catchment: null,
            },
        };

        if (healthCenter.healthCenterInformation.catchment.find((catchment) => catchment.catchmentType === 'WEEK_DAY' && catchment.details !== '')) {
            hasCatchment.weekDay.isAvailable = true;
            hasCatchment.weekDay.catchment = healthCenter.healthCenterInformation.catchment.find((catchment) => catchment.catchmentType === 'WEEK_DAY');
        }

        if (healthCenter.healthCenterInformation.catchment.find((catchment) => catchment.catchmentType === 'WEEK_END' && catchment.details !== '')) {
            hasCatchment.weekEnd.isAvailable = true;
            hasCatchment.weekEnd.catchment = healthCenter.healthCenterInformation.catchment.find((catchment) => catchment.catchmentType === 'WEEK_END');
        }

        return hasCatchment;
    }

    const healthCenterServices = (healthCenterPrimaryService, healthCenterSecoundryService) => {
        return healthCenterPrimaryService.concat(healthCenterSecoundryService);
    }

    const healthCenterLocation = (healthCenter) => {
        return {
            lat: healthCenter.healthCenterInformation.coordinates.latitude,
            lng: healthCenter.healthCenterInformation.coordinates.longitude,
            address: healthCenter.centerName.nameEng,
        }
    }

    const serviceTag = (service, index) => {

        const timingIndex = [
            {en: '7 am - 2 pm', ar: '7 صباحاً - 2 مساءاً'},
            {en: '4 pm - 8 pm', ar: '4 مساءاً - 8 مساءاً'},
            {en: '9 pm - 12 am', ar: '9 مساءاً - 12 صباحاً'},
            {en: '24 hours', ar: '24 ساعة'},
        ]

        const serviceIconIndex = servicesIcon.findIndex((icon) => icon.key === service.nameFromServer);

        const workingTime = () => {
            const shiftTypes = ['morning', 'evening', 'overtime', 'night'];
            const openShift = shiftTypes.filter(shift => service.details && service.details[shift] && service.details[shift].shift === 'yes');

            switch (openShift.length) {
                case 4:
                    return i18n.language === 'ar' ? '24 ساعة' : '24 hours';
                case 3:
                    return i18n.language === 'ar' ? '7 صباحاً - 12 مساءاً' : '7 am - 12 am';
                case 2:
                    return i18n.language === 'ar' ? '7 صباحاً - 9 مساءاً' : '7 am - 9 pm';
                case 1:
                    return i18n.language === 'ar' ? '7 صباحاً - 2 مساءاً' : '7 am - 2 pm';
                default:
                    return '';
            }
        }

        const workingDays = () => {
            const shiftTypes = ['morning', 'evening', 'overtime', 'night'];
            let openingDays = [];

            for (let i = 0; i < shiftTypes.length; i++) {
                if (service.details && service.details[shiftTypes[i]] && service.details[shiftTypes[i]].shift === 'yes' && service.details[shiftTypes[i]].weekDays) {
                    if (service.details[shiftTypes[i]].weekDays) {
                        openingDays[i] = service.details[shiftTypes[i]].weekDays;
                    }
                }
            }

            return openingDays.map((shift, index) => shift.join(', '));
        }

        const capitalizeFirstLetter = (string) => {
            const splitString = string.split(', ');
            const result = splitString.map((word) => {
                return word.charAt(0).toUpperCase() + word.slice(1);
            });

            return result.join(', ');
        }

        const numberOfDays = (shift) => {
            return shift.split(', ').length;
        }

        if (!service.name) {
            return null;
        }

        return (
            <div
                key={index}
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: 180,
                    minHeight: !isMobile && serviceIconIndex > -1 && 320,
                }}
            >
                <div
                    style={{
                        backgroundImage: serviceIconIndex > -1 ? `url('./assets/img/health-center-bubble.png')` : 'none',
                        backgroundSize: 'cover',
                        width: 180,
                        minHeight: serviceIconIndex > -1 && 100,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    {
                        serviceIconIndex > -1 && (
                            <FontAwesomeIcon
                                icon={servicesIcon[serviceIconIndex].icon}
                                style={{
                                    fontSize: '1.5em',
                                }}
                            />
                        )
                    }
                </div>
                <p color="blue">{i18n.language === 'ar' ? service.name.nameArb : service.name.nameEng}</p>
                {
                    workingDays().length > 0 &&
                    workingDays().map((shift, index) => {
                        return (
                            <div
                                key={index}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                    justifyContent: 'space-evenly',
                                    margin: 5,
                                    fontSize: 'small',
                                }}
                            >
                                <p>{capitalizeFirstLetter(shift)}</p>
                                <p>{i18n.language === 'ar' ? timingIndex[index].ar : timingIndex[index].en}</p>
                            </div>
                        )
                    })
                }
                {
                    workingDays().length === 0 &&
                    <p>{workingTime()}</p>
                }
            </div>
        )
    }

    return (
        <Row
            style={{
                backgroundColor: "#fff",
                padding: "10px",
                paddingRight: "11px",
                paddingBottom: "20px",
                borderRadius: "10px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
            }}
        >
            <Col
                span={24}
                style={{
                    width: '600px', textAlign: "center", margin: "35px 0", borderRadius: "10px", padding: "20px",
                }}
            >
                <h4>{i18n.language === 'ar' ? healthCenter.centerName.nameArb : healthCenter.centerName.nameEng}</h4>
                <Row style={{marginTop: "40px", alignItems: "center", justifyContent: "center"}}>
                    <Col span={isMobile ? 16 : 12}>
                        <div style={{
                            display: 'flex',
                            flexDirection: i18n.dir() === 'rtl' ? 'row-reverse' : 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <CalendarTwoTone style={{marginRight: "10px"}}/>
                            <span style={{fontWeight: 'bold'}}>{t('healthCenterInformation.workingDays')}</span>
                        </div>
                        <p>{healthCenterDaysFormat(healthCenter.weekendDuty)}</p>
                    </Col>
                    <Col span={isMobile ? 16 : 12}>
                        <div style={{
                            display: 'flex',
                            flexDirection: i18n.dir() === 'rtl' ? 'row-reverse' : 'row',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <ClockCircleTwoTone style={{marginRight: "10px"}}/>
                            <span style={{fontWeight: 'bold'}}>{t('healthCenterInformation.workingHours')}</span>
                        </div>
                        <p>{healthCenterHoursFormat(healthCenter.workingHours)}</p>
                    </Col>
                </Row>
                <Row style={{marginTop: "40px"}}>
                    <Col span={24}>
                        <div style={{
                            display: 'flex',
                            flexDirection: i18n.dir() === 'rtl' ? 'row-reverse' : 'row',
                            alignItems: 'center',
                            justifyContent: 'center',
                            marginBottom: "20px"
                        }}>
                            <CheckSquareTwoTone style={{marginRight: "10px"}}/>
                            <span style={{fontWeight: 'bold'}}>{t('healthCenterInformation.catchment')}</span>
                        </div>
                        {catchmentTabs(healthCenter)}
                    </Col>
                </Row>
                <Row
                    style={{
                        marginTop: "30px",
                    }}
                >
                    <Col
                        span={24}
                        style={{
                            display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
                        }}
                    >
                        <h6
                            style={{fontWeight: "bold"}}
                        >
                            {t('home.healthCenterInformation.service.title')}
                        </h6>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                flexWrap: 'wrap',
                                marginTop: "20px",
                            }}
                        >
                            {healthCenterServices(healthCenter.primaryServices, healthCenter.secondaryServices)
                                .sort((a, b) => {
                                    // check if the service have icon on the servicesIcon array set in first
                                    const serviceIconIndexA = servicesIcon.findIndex((icon) => icon.key === a.nameFromServer);
                                    const serviceIconIndexB = servicesIcon.findIndex((icon) => icon.key === b.nameFromServer);
                                    return serviceIconIndexA > -1 ? -1 : serviceIconIndexB > -1 ? 1 : 0;
                                })
                                .map((item, index) => {
                                    return (serviceTag(item, index))
                                })}
                        </div>
                    </Col>
                </Row>
                <Row
                    style={{
                        marginTop: "50px",
                    }}
                >
                    <Col
                        span={24}
                        style={{
                            display: 'flex',
                            flexDirection: i18n.dir() === 'rtl' ? 'column-reverse' : 'column',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Map location={healthCenterLocation(healthCenter)}/>
                    </Col>
                </Row>
            </Col>
        </Row>
    )
}
