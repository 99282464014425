// get window height
const windowHeight = window.innerHeight;

const NotFoundComponent = () => {

    return (
        <div
            style={{
                height: windowHeight - 200,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center"
            }}
        >
            <img className={'img-fluid'} src={'/assets/img/404.png'} width={'600px'} height={'600px'}
                 alt={'not-found'}/>
            <h1>Page Not Found</h1>
            <p>Sorry, that page does not exist.</p>
        </div>
    )
}

export default NotFoundComponent;
