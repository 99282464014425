import {useQuery} from "react-query";
import service from "../../config/service";
import {Card, Carousel, Col, PageHeader, Row, Spin, Table} from "antd";
import React, {createRef, useState} from "react";
import {useTranslation} from "react-i18next";
import useWindowDimensions from "../../utils/utils";
import {logoutUser} from "../../redux/actions/userActions";
import {Redirect} from "react-router";
import {useDispatch} from "react-redux";

const VideosComponent = () => {

    const {t} = useTranslation();
    const {height, width} = useWindowDimensions();
    const dispatch = useDispatch();

    const [selectedCategory, setSelectedCategory] = useState({})
    const {data, isError, isLoading} = useQuery(['videos'], async () => {
        const res = await service.get('/file/video');

        return res.data
    }, {refetchOnWindowFocus: false});

    if (isError) {
        dispatch(logoutUser());
        return <Redirect to="/login"/>;
    }


    if (isLoading) {

        return (
            <div style={{
                height: height * 0.65,
                minHeight: height * 0.65,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
            }}
            >
                <Spin
                    spinning={true}
                    size="default"
                ></Spin>
                <div className="text-center">{t('pleaseWait')}</div>
            </div>
        );
    }

    const carousel = createRef();
    const list = [];

    if (data) {


        data.forEach((category, index, array) => {


            const key = Object.keys(category)[0];
            let videos = [];

            Object.values(category)[0].forEach(video => {

                const key1 = Object.keys(video)[0];
                if (key1 === 'videos') {
                    Object.values(video[key1]).forEach(v => {
                        videos.push({
                            name: v.split('.')[0],
                            link: `${process.env.REACT_APP_PORTAL_FILES_URL}/video/${key}/videos/${v}`,
                            thumbnails: `${process.env.REACT_APP_PORTAL_FILES_URL}/video/${key}/thumbnails/${v.split('-')[0]}.png`,
                            youtube: false
                        })
                    })
                }

                if (video === 'youtube.json') {
                    service.get(`${process.env.REACT_APP_PORTAL_FILES_URL}/video/${key}/youtube.json`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/json'
                        }
                    })
                        .then(result => {

                            result.data.forEach(v => {
                                videos.push({
                                    name: v.name,
                                    link: v.url,
                                    thumbnails: '',
                                    youtube: true,
                                    isEnable: v.isEnable
                                })
                            })
                        });
                }
            })

            // Object.values(category)[0].forEach(video => videos.push({
            //     name: video.split('.')[0],
            //     link: `${process.env.REACT_APP_PORTAL_FILES_URL}/video/${key}/videos/${video}`
            // }))

            videos = videos.sort((a, b) => a.name > b.name ? 1 : -1);

            const obj = {
                category: key,
                videos: videos
            }

            list.push(obj);
        })
    }

    const categoryClick = (category) => {
        setSelectedCategory(category);
        carousel.current.next();
    }

    const carouselBack = (category) => {
        carousel.current.prev();
    }

    const columns = [
        {
            title: '',
            dataIndex: 'name',
            key: 'name',
            className: style.table.header,
            render: (text, record) => {

                if (!record.youtube) {
                    return (
                        <Card title={text.split('-')[1]}>
                            <video style={style.video} key={record.link} width="450" controls
                                   poster={record.thumbnails}>
                                <source src={record.link} type="video/mp4"/>
                            </video>
                        </Card>
                    );
                }

                if (record.youtube && record.isEnable) {
                    return (
                        <Card style={style.container}>
                            <iframe style={style.responsiveIframe} src={record.link}
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                        </Card>
                    );
                }
            },
        }
    ];

    return (
        <div className="container" style={{padding: '20px', minHeight: (height * 0.65),}}>
            <Carousel
                ref={carousel}
                style={{
                    display: 'grid',
                    gridTemplateColumns: "repeat(minmax(2, 1fr))",
                }}
            >
                <div>
                    {list.map((value, index) =>
                        <Row gutter={16}>
                            <Col span={16}>
                                <Card
                                    style={style.gridStyle}
                                    hoverable={false}
                                    key={index}
                                    onClick={() => categoryClick(value)}
                                >
                                    <div className="row">
                                        <div className="col-md-12" style={{display: 'flex', justifyContent: 'center'}}>
                                            <img className="img-fluid" alt="example" style={{width: '150px'}}
                                                 src="/assets/img/video-folder.png"/>
                                        </div>
                                    </div>
                                    <div className="row" style={{marginTop: '20px'}}>
                                        <div className="col-md-12">
                                            <p>{value.category}</p>
                                        </div>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    )}
                </div>
                <div>
                    {
                        selectedCategory.category ?
                            <div>
                                <PageHeader
                                    className="site-page-header"
                                    onBack={() => carouselBack(selectedCategory)}
                                    title={
                                        <label>
                                            {selectedCategory.category}
                                        </label>
                                    }
                                    style={{
                                        background: 'white'
                                    }}
                                />

                                <Table dataSource={selectedCategory.videos} columns={columns}/>
                            </div>
                            : null
                    }
                </div>
            </Carousel>
        </div>
    );
}

const style = {
    container: {
        position: 'relative',
        overflow: 'hidden',
        width: '100%',
        paddingTop: '56.25%'
    },
    responsiveIframe: {
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        width: '100%',
        height: '100%',
    },
    video: {
        width: '100%',
        height: '100%'
    },
    gridStyle: {
        textAlign: 'center',
    },
    table: {
        header: {
            display: 'none'
        }
    }
}

export default VideosComponent;
