import {useQuery} from "react-query";
import service from '../../config/service';
import {Spin, Alert} from 'antd';
import {useDispatch, useSelector} from "react-redux";
import {Redirect} from "react-router";
import Documents from "./Documents";
import React from "react";
import {useTranslation} from "react-i18next";
import useWindowDimensions from "../../utils/utils";
import {logoutUser} from "../../redux/actions/userActions";

const CircularsComponent = () => {

    const {height, width} = useWindowDimensions();
    const dispatch = useDispatch();
    const userReducer = useSelector(reducer => reducer.userReducer);

    const {t} = useTranslation();

    const {data, isError, isLoading} = useQuery(['circulars'], async () => {
        const res = await service.get('/circulars/enable')
        return res.data
    }, {refetchOnWindowFocus: false});

    if (!userReducer.loggedIn)
        return <Redirect to="/login/circulars"/>

    if (isLoading) {

        return (
            <div style={{
                height: height * 0.65,
                minHeight: height * 0.65,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
            }}
            >
                <Spin
                    spinning={true}
                    size="default"
                ></Spin>
                <div className="text-center">{t('pleaseWait')}</div>
            </div>
        );
    }

    if (isError) {
        dispatch(logoutUser());
        return <Redirect to="/login"/>;
    }

    return (<Documents data={data} title='Circulars' for='circulars'/>);
}

export default CircularsComponent;
