import {useSelector} from "react-redux";
import {Redirect} from "react-router";
import NavComponent from "../common/nav";
import HeaderComponent from "../common/header";
import FooterComponent from "../common/footer";
import React from "react";
import ProtocolsComponent from "../common/protocols";
import {useTranslation} from "react-i18next";

const ProtocolsPage = () => {

    const userReducer = useSelector(reducer => reducer.userReducer);

    const {t} = useTranslation();

    if (!userReducer.loggedIn)
        return <Redirect to={"/login/protocols"}/>

    return (
        <>
            <NavComponent/>
            <HeaderComponent section="Protocols"/>
            <section>
                <div className="mycontainer">
                    <h1 className="hero_text hero_text_align_center" style={{color: 'white'}}>
                        {t("views.documents.protocols")}
                    </h1>
                    <ProtocolsComponent/>
                </div>
            </section>
            <FooterComponent />
        </>
    )

}

export default ProtocolsPage;
