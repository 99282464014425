import SetPassword from "../auth/setPassword";
import NavComponent from "../common/nav";
import FooterComponent from "../common/footer";
import HeaderComponent from "../common/header";
import { useSelector } from "react-redux";
import { Redirect, useParams } from "react-router";

const UpdatePasswordPage = () => {
    const userReducer = useSelector(state => state.userReducer);
    const {token} = useParams();

    if (!userReducer.loggedIn && !token)
        return <Redirect to="/login/updatePassword" />

    return (
        <>
            <NavComponent />
            <HeaderComponent />
            <SetPassword />
            <FooterComponent />
        </>
    );
}

export default UpdatePasswordPage;