import React from "react";
import { Card, Col, Row } from "antd";
import moment from "moment";
import { useState, useEffect } from "react";
import axios from "axios";

const EventCard = (value) => {
  let eventDetails = null;
  const [startDate, setStartDate] = useState("");
  const [duration, setDuration] = useState("");
  const [summary, setSummary] = useState("");
  const eventId = value.eventId;
  const eventURL = `https://www.googleapis.com/calendar/v3/calendars/ads9ovestqt8ungnvfe19re29c@group.calendar.google.com/events/${eventId}?key=AIzaSyDfHtUfh1CijqmBm29wsFMBBP0oR9gFAdM`;

  useEffect(() => {
    const fetcheEventDetails = async () => {
      axios
        .get(eventURL)
        .then((resp) => {
          eventDetails = resp.data;
          setStartDate(moment(eventDetails.start.dateTime).format("llll"));
          setDuration(
            moment(eventDetails.end.dateTime).diff(
              moment(eventDetails.start.dateTime),
              "hours"
            )
          );
          setSummary(eventDetails.summary);
        })
        .catch((error) => {

        });
    };
    fetcheEventDetails();
  });

  return (
    <Row justify="center">
      <Col span={8}>
        <Card id="CalendarCard" title={summary} key="{key}">
          <p>Start : {startDate}</p>
          <p>Duration: {duration} hour </p>
        </Card>
      </Col>
    </Row>
  );
};

export default EventCard;
