import {useSelector} from "react-redux";
import {Redirect} from "react-router";
import NavComponent from "../common/nav";
import HeaderComponent from "../common/header";
import FooterComponent from "../common/footer";
import React from "react";
import BookletsComponent from "../common/booklets";
import InformationComponent from "../common/information";
import {useTranslation} from "react-i18next";

const InformationPage = () => {

    const userReducer = useSelector(reducer => reducer.userReducer);

    const {t} = useTranslation();

    if (!userReducer.loggedIn)
        return <Redirect to={"/login/information"}/>

    return (
        <>
            <NavComponent/>
            <HeaderComponent section="Information"/>
            <section>
                <div className="mycontainer">
                    <h1 className="hero_text hero_text_align_center" style={{color: 'white'}}>
                        {t("views.documents.information")}
                    </h1>
                    <InformationComponent/>
                </div>
            </section>
            <FooterComponent />
        </>
    )
}

export default InformationPage;
