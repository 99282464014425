import React, {useEffect} from "react";
import {Form, Input, Button, Alert, Spin, Space} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {fetchUser} from "../../redux/actions/userActions";
import {Link, useParams, Redirect} from "react-router-dom";
import useWindowDimensions, {isLocalIT} from "../../utils/utils";
import {useTranslation} from "react-i18next";

const Login = () => {

    const dispatch = useDispatch();
    const userReducer = useSelector((state) => state.userReducer);
    const {page} = useParams();
    const {height, width} = useWindowDimensions();

    const {t, i18n} = useTranslation();

    useEffect(() => {
        if (isLocalIT()) {
            dispatch(fetchUser({username: 'api', password: 'Den@Yousef@ziz@?'}))
        }
    }, [])

    if (userReducer.loggedIn && page) {
        return <Redirect to={`/${page}`}/>
    } else if (userReducer.loggedIn) {
        return <Redirect to="/"/>
    }

    const setOneSignal = async () => {
        try {
            // await OneSignal.init({appId: '4a1412c1-9b2c-4e72-885e-da9a32740a28', allowLocalhostAsSecureOrigin: true});
            // await OneSignal.showSlidedownPrompt();
            //
            // await OneSignal.getUserId().then(async (userId) => {
            //     console.log(userId)
            //     // await service.put(`/device/oneSignal`, {employee: res.data.employee.id, oneSignalId: userId});
            // });
        } catch (e) {
            return e;
        }
    }

    return (

        !isLocalIT() ?
            <div
                style={{
                    minHeight: (height * 0.65),
                    padding: 15,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around"
                }}
            >
                <div className="page_title">
                    <h1
                        style={{
                            marginBottom: "80px",
                            color: 'white',
                            fontFamily: "Montserrat, sans-serif",
                            fontWeight: 800,
                            fontOpticalSizing: "auto",
                            fontStyle: "normal",
                            textShadow: "0 0 1px #ffffff",
                        }}
                    >
                        {t("views.login.title")}
                    </h1>
                </div>
                <div
                    className="col-md-4 offset-md-4"
                    style={{
                        direction: i18n.dir(),
                        textAlign: i18n.dir() === "rtl" ? "right" : "left",
                        background: "white",
                        padding: 10,
                        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
                        borderRadius: 8,
                    }}
                >
                    {/* {showSuccessOrNot()} */}

                    <h6
                        // style={{
                        //     color: "white",
                        //     fontFamily: "Montserrat, sans-serif",
                        //     fontOpticalSizing: "auto",
                        //     fontStyle: "normal",
                        //     textShadow: "0 0 1px #ffffff",
                        // }}
                    >
                        {t("views.login.subtitle")}
                    </h6>
                    <Form
                        layout="vertical"
                        width="40%"
                        name="login"
                        initialValues={{remember: true}}
                        onFinish={(values) => {
                            dispatch(fetchUser(values))
                        }}
                        style={{
                            marginTop: "30px",
                        }}
                    >
                        <Form.Item
                            label={t("views.login.email")}
                            name="username"
                            rules={[{required: true, message: t('views.login.errors.email')}]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label={t("views.login.password")}
                            name="password"
                            rules={[{required: true, message: t('views.login.errors.password')}]}
                        >
                            <Input type="password"/>
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={userReducer.loading}
                                disabled={userReducer.loggedIn}
                            >
                                {t("views.login.submit")}
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <div
                                style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    alignItems: "flex-start",
                                    flexDirection: "column",
                                }}
                            >
                                <Link to="/forget">
                                    {t("views.login.forgotPassword")}
                                </Link>
                                <a
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    href="https://register.cdphc.com"
                                >
                                    {t("views.login.register")}
                                </a>
                            </div>
                        </Form.Item>
                    </Form>
                    {userReducer.logginErrorMessage ? (
                        <Alert message={userReducer.logginErrorMessage} type="error"/>
                    ) : (
                        ""
                    )}
                </div>
            </div> :
            <Space
                style={{
                    "display": "flex",
                    "flex-direction": "row",
                    "align-items": "center",
                    "justify-content": "center",
                }}
                size="middle"
                spinning={userReducer.loading}
            >
                <Spin size="large"/>
            </Space>
    );
};

export default Login;
