import CircularsComponent from "../common/circulars";
import NavComponent from "../common/nav";
import HeaderComponent from "../common/header";
import FooterComponent from "../common/footer";
import {useTranslation} from "react-i18next";

const CircularsPage = () => {

    const {t} = useTranslation();

    return (
        <>
            <NavComponent/>
            <HeaderComponent section="Circulars"/>
            <section>
                <div className="mycontainer">
                    <h1 className="hero_text hero_text_align_center" style={{color: 'white'}}>
                        {t("views.documents.circulars")}
                    </h1>
                    <CircularsComponent/>
                </div>
            </section>
      <FooterComponent />
    </>
  );
};

export default CircularsPage;
