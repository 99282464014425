import {useEffect, useState} from "react";
import {Button, Form} from "antd";
import FormBuilder from "antd-form-builder";
import moment from "moment";
import service from "../../config/service";
import PersonalInformationEditComponent from "./personalInformationEdit";
import {useTranslation} from "react-i18next";

const ProfileComponent = (props) => {

    const {t, i18n} = useTranslation();

    const [employeeObj, setEmployeeObj] = useState(
        JSON.parse(localStorage.getItem("employee"))
    );

    const [emptyValues, setEmptyValues] = useState(0);

    const [form] = Form.useForm();
    const [isPersonalInformationModalVisible, setIsPersonalInformationModalVisible] = useState(false);
    const [isJobInformationModalVisible, setIsJobInformationModalVisible] = useState(false);

    const showJobInformationModal = () => {
        setIsJobInformationModalVisible(true);
    };

    const handleJobInformationOk = () => {
        setIsJobInformationModalVisible(false);
    };

    const handleJobInformationCancel = () => {
        setIsJobInformationModalVisible(false);
    };

    const meta = {
        fields: [
            {
                key: "doj",
                label: "",
                widget: "date-picker",
                onChange: () => {
                    if (form.isFieldTouched("confirmPassword")) {
                        form.validateFields(["confirmPassword"]);
                    }
                },
                rules: [
                    // This is equivalent with "required: true"
                    {
                        required: true,
                        message: "Please Your Date of Join.",
                    },
                ],
            },
        ],
    };

    useEffect(() => {
        let keys = Object.keys(employeeObj.jobInformation);
        Object.values(employeeObj.jobInformation).forEach((value, index, array) => {
            if (value === null) {
                props.setEmptyValues(emptyValues + 1);
            }
        });
    }, []);

    const jobInformationUpdate = (value) => {
        employeeObj.jobInformation.doj = moment(value.doj).unix();
        service
            .put("/jobInformation", employeeObj.jobInformation)
            .then((response) => response)
            .then((response) => {
                if (response) {
                    employeeObj.jobInformation.doj = new Date(
                        moment(value.doj).unix() * 1000
                    );
                    localStorage.setItem("employee", JSON.stringify(employeeObj));
                    let obj2 = {};
                    Object.assign(obj2, employeeObj);
                    setEmployeeObj(obj2);
                }
            })
            .catch((error) => error);
    };

    return (
        <>
            <div className="page_title">
                <h1>
                    {t("views.profile.title")}
                </h1>
            </div>
            <div
                className="container profile"
                style={{
                    direction: i18n.dir(),
                    textAlign: i18n.dir() === "rtl" ? "right" : "left",
                }}
            >
                <div className="row">
                    <div className="col-md-12 personalInformation sectionBorder">

                        {/* for add edit profile */}

                        {/*<div className="row">*/}
                        {/*    <PersonalInformationEditComponent data={employeeObj.personalInformation} />*/}
                        {/*</div>*/}

                        <div className="row">
                            <div className="col-md-4">
                                <h4>
                                    {t("views.profile.name")}
                                </h4>
                                <p>{i18n.language === 'en' ? employeeObj.personalInformation.name.nameEng : employeeObj.personalInformation.name.nameArb.length > 0 ? employeeObj.personalInformation.name.nameArb : employeeObj.personalInformation.name.nameEng}</p>
                            </div>
                            <div className="col-md-4">
                                <h4>
                                    {t("views.profile.cid")}
                                </h4>
                                <p>{employeeObj.personalInformation.cid}</p>
                            </div>
                            <div className="col-md-4">
                                <h4>
                                    {t("views.profile.mobile")}
                                </h4>
                                <p>{employeeObj.personalInformation.phoneNumber}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <Form onFinish={jobInformationUpdate}>

                    <div className="row">
                        <div className="col-md-12 jobInformation sectionBorder">
                            {/*<div className="row">*/}
                            {/*    <div className="col-md-12">*/}
                            {/*        <Button type="link" className="float-right">*/}
                            {/*            Edit*/}
                            {/*        </Button>*/}
                            {/*    </div>*/}
                            {/*</div>*/}
                            <div style={{marginTop: 10}} className="row">
                                <div className="col-md-4">
                                    <h4>
                                        {t("views.profile.occupation")}
                                    </h4>
                                    <p>
                                        {
                                            i18n.language === 'en' ? employeeObj.jobInformation.jobTitle.occupations.name
                                                    .nameEng :
                                                employeeObj.jobInformation.jobTitle.occupations.name.nameArb.length > 0 ?
                                                    employeeObj.jobInformation.jobTitle.occupations.name.nameArb :
                                                    employeeObj.jobInformation.jobTitle.occupations.name.nameEng
                                        }
                                    </p>
                                </div>
                                <div className="col-md-4">
                                    <h4>
                                        {t("views.profile.qualification")}
                                    </h4>
                                    <ul
                                        style={{
                                            listStyleType: "none",
                                        }}
                                    >
                                        {employeeObj.jobInformation.qualifications.map((q) => (
                                            <li>
                                                {
                                                    i18n.language === 'en' ? q.name.nameEng :
                                                        q.name.nameArb.length > 0 ? q.name.nameArb : q.name.nameEng
                                                }
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                                <div className="col-md-4">
                                    <h4>
                                        {t("views.profile.fileNo")}
                                    </h4>
                                    <p>{employeeObj.jobInformation.fileNo}</p>
                                </div>
                            </div>
                            <div style={{marginTop: 10}} className="row">
                                <div className="col-md-4">
                                    <h4>
                                        {t("views.profile.jobTitle")}
                                    </h4>
                                    <p>
                                        {
                                            i18n.language === 'en' ? employeeObj.jobInformation.jobTitle.name.nameEng :
                                                employeeObj.jobInformation.jobTitle.name.nameArb.length > 0 ?
                                                    employeeObj.jobInformation.jobTitle.name.nameArb :
                                                    employeeObj.jobInformation.jobTitle.name.nameEng
                                        }
                                    </p>
                                </div>
                                <div className="col-md-4">
                                    <h4>
                                        {t("views.profile.healthArea")}
                                    </h4>
                                    <p>
                                        {
                                            i18n.language === 'en' ? employeeObj.jobInformation.healthArea.name.nameEng :
                                                employeeObj.jobInformation.healthArea.name.nameArb.length > 0 ?
                                                    employeeObj.jobInformation.healthArea.name.nameArb :
                                                    employeeObj.jobInformation.healthArea.name.nameEng
                                        }
                                    </p>
                                </div>
                                <div className="col-md-4">
                                    <h4>
                                        {t("views.profile.healthCenter")}
                                    </h4>
                                    <p>
                                        {
                                            i18n.language === 'en' ? employeeObj.jobInformation.healthCenter.name.nameEng :
                                                employeeObj.jobInformation.healthCenter.name.nameArb.length > 0 ?
                                                    employeeObj.jobInformation.healthCenter.name.nameArb :
                                                    employeeObj.jobInformation.healthCenter.name.nameEng
                                        }
                                    </p>
                                </div>
                            </div>
                            <div style={{marginTop: 10}} className="row">
                                <div className="col-md-12">
                                    <h4>
                                        {t("views.profile.doj")}
                                    </h4>
                                    {employeeObj.jobInformation.doj === null ? (
                                        <FormBuilder meta={meta}/>
                                    ) : (
                                        <div>
                                            <p>
                                                {moment(employeeObj.jobInformation.doj).format(
                                                    "DD-MMM-YYYY"
                                                )}
                                            </p>
                                        </div>
                                    )}
                                </div>
                            </div>
                            {employeeObj.jobInformation.doj === null ? (
                                <div className="row">
                                    <div className="col-md-12">
                                        <Button type="primary" htmlType="submit">
                                            Submit
                                        </Button>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </Form>
            </div>
        </>
    );
};

export default ProfileComponent;
