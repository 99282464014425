import {Col, Row, Tooltip, Dropdown, Space, Menu, Divider, List, Card} from "antd";
import InfiniteScroll from 'react-infinite-scroll-component';
import {useTranslation} from "react-i18next";
import {useEffect} from "react";

const MobileView = (props) => {

    const {t, i18n} = useTranslation();

    const {
        healthCenterData,
        selectedHealthArea,
        onSelectHealthArea,
        onSelectHealthCenter,
        healthCenter,
        isSearch,
        searchValue,
        filteredHealthCenters
    } = props;

    const healthAreaItemRender = (item, index) => {
        return (
            <div
                key={index}
                style={{
                    alignSelf: 'flex-start',
                    marginRight: '20px',
                    padding: '10px',
                    textAlign: i18n.dir() === 'rtl' ? 'right' : 'left',
                }}
                onClick={() => {
                    onSelectHealthArea(item)
                }}
            >
                <h6
                    style={{
                        width: '150px',
                    }}
                >
                    {i18n.language === 'ar' ? item.title.ar : item.title.en}
                </h6>
            </div>
        )
    }

    const healthCenterItemRender = (item, index) => {
        item.key = index;
        return (
            <List.Item
                key={index}
                onClick={() => {
                    onSelectHealthCenter(item)
                }}
            >
                <Card key={index}>
                    <p
                        style={{textAlign: i18n.language === 'ar' ? 'right' : 'left'}}
                    >
                        {i18n.language === 'ar' ? item.centerName.nameArb : item.centerName.nameEng}
                    </p>
                </Card>
            </List.Item>
        )
    }

    return (
        <div>
            {
                !isSearch && searchValue === '' &&
                <div
                    id="scrollableAreasDiv"
                    style={{
                        marginTop: '40px',
                        padding: '16px 8px',
                        border: '1px solid rgba(140, 140, 140, 0.05)',
                    }}
                >
                    <Row>
                        <Col>
                            <h3>{t('home.mobile.healthAreas')}</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <InfiniteScroll
                                dataLength={healthCenterData.length}
                                scrollableTarget="scrollableAreasDiv"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'space-between',
                                    alignItems: 'center',
                                    marginTop: '10px',
                                }}
                            >
                                {
                                    healthCenterData.map(healthAreaItemRender)
                                }
                            </InfiniteScroll>
                        </Col>
                    </Row>
                </div>
            }
            {
                healthCenterData[selectedHealthArea] && !healthCenter && filteredHealthCenters.length > 0 &&
                <div
                    id="scrollableCentersDiv"
                    style={{
                        marginTop: '20px',
                        padding: '16px 8px',
                        border: '1px solid rgba(140, 140, 140, 0.05)',
                    }}
                >
                    {
                        !isSearch && searchValue === '' &&
                        <Row>
                            <Col>
                                <h5>
                                    <b>
                                        <u>{i18n.language === 'ar' ? healthCenterData[selectedHealthArea].label.ar : healthCenterData[selectedHealthArea].label.en}</u>
                                    </b>
                                    &nbsp; {t('home.mobile.healthCenters')}
                                </h5>
                            </Col>
                        </Row>
                    }
                    <Row>
                        <Col>
                            <List
                                itemLayout="vertical"
                                grid={{gutter: 16, column: 2}}
                                style={{padding: "15px"}}
                                dataSource={!isSearch && searchValue === '' ? healthCenterData[selectedHealthArea].items : filteredHealthCenters}
                                renderItem={healthCenterItemRender}
                            />
                        </Col>
                    </Row>
                </div>
            }
        </div>
    );
}

export default MobileView;
