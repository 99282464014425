import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: 'en',
        resources: {
            'en-US': {
                translations: require('./locales/en/translations.json')
            },
            en: {
                translations: require('./locales/en/translations.json')
            },
            ar: {
                translations: require('./locales/ar/translations.json')
            }
        },
        ns: ['translations'],
        defaultNS: 'translations',
        interpolation: {
            escapeValue: false,
        }
    });

i18n.languages = ['en', 'ar'];

export default i18n;
