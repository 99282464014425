import React from "react";
import { useMutation, useQueryClient } from "react-query";
import service from "../../config/service";
import { Button } from "antd";

const EmployeeEnroll = (props) => {
  const employeeId = props.employeeId;
  const eventId = props.eventId;
  const setActiveSearch = props.setActiveSearch;
  const queryClient = useQueryClient();
  const Enrollregistrant = async (user) => {
    const enrollres = await service.post("/eventsRegistrants", user);
    return enrollres.data;
  };

  // const Enroll = useMutation((value) => {
  const Enroll = useMutation(
    (user) => {
      Enrollregistrant(user);
      setActiveSearch(false);
      props.callBack(true);
    },
    {
      onSuccess: () => queryClient.invalidateQueries("nomination"),
      onError: () => console.log("failed adding"),
    }
  );
  return (
    <Button
      type="primary"
      onClick={() => {
        Enroll.mutate({
          id: "",
          fileNo: employeeId,
          googleEventsId: eventId,
        });
      }}
    >
      Enroll
    </Button>
  );
};

export default EmployeeEnroll;
