import React from 'react';
import NavComponent from "../common/nav";
import FooterComponent from "../common/footer";
import HeaderComponent from "../common/header";
import PoliciesComponent from '../common/policies';
import { Redirect, useParams } from 'react-router';
import {useSelector} from 'react-redux';
import {useTranslation} from "react-i18next";

const PoliciesPage  = () => {
    const userReducer = useSelector(reducer => reducer.userReducer);

    const {t} = useTranslation();

    if (!userReducer.loggedIn)
        return <Redirect to={"/login/policies"}/>

    return (
        <>
            <NavComponent/>
            <HeaderComponent section="Policy and procedures manual"/>
            <section>
                <div className="mycontainer">
                    <h1 className="hero_text hero_text_align_center" style={{color: 'white'}}>
                        {t("views.documents.policies")}
                    </h1>
                    <PoliciesComponent/>
                </div>
            </section>
            <FooterComponent />
        </>
    )
}

export default PoliciesPage;
