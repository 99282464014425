import service from "../../config/service";
import {Alert, Button, Form, Input} from "antd";
import React from "react";
import useWindowDimensions from "../../utils/utils";
import {useTranslation} from "react-i18next";

const ContactComponent = () => {

    const [isLoading, setIsLoading] = React.useState(false);
    const formRef = React.useRef(null);
    const {height, width} = useWindowDimensions();

    const {t, i18n} = useTranslation();

    const onFinish = async (values) => {
        setIsLoading(true);
        await service.post('/contactMessage', values)
            .then(res => {
                setIsLoading(false);
                alert(t('views.contactus.success'));
                formRef.current.resetFields();
            })
            .catch(err => {
                setIsLoading(false);
            });
    };

    const onFinishFailed = (errorInfo) => {
        Alert.error('Failed to submit the form. Please try again later.');
    };

    return (
        <div
            dir={i18n.dir()}
            style={{
                minHeight: (height * 0.65),
                padding: 15,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-evenly"
            }}
        >
            <h1 className="hero_text hero_text_align_center" style={{color: 'white'}}>
                {t('views.contactus.title')}
            </h1>
            <Form
                ref={formRef}
                name="basic"
                labelCol={{
                    span: 8,
                }}
                wrapperCol={{
                    span: 16,
                }}
                initialValues={{
                    remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
            >
                <Form.Item
                    label={t('views.contactus.subject')}
                    name="subject"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Subject',
                        },
                    ]}
                >
                    <Input/>
                </Form.Item>
                <Form.Item
                    label={t('views.contactus.message')}
                    name="message"
                    rules={[
                        {
                            required: true,
                            message: 'Please Enter Message',
                        },
                    ]}
                >
                    <Input.TextArea
                        autosize={{minRows: 5, maxRows: 20}}
                    />
                </Form.Item>
                <Form.Item
                    wrapperCol={{
                        offset: 8,
                        span: 16,
                    }}
                >
                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={isLoading}
                    >
                        {t('views.contactus.submit')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
}

export default ContactComponent;
