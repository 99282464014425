import QueueAnim from 'rc-queue-anim';
import React, {useRef} from 'react';
import {Col, Row} from "antd";

export default (props) => {

    const {children} = props;

    return (
        <QueueAnim
            component={Row}
            type={['right', 'left']}
            leaveReverse
            ease={['easeOutQuart', 'easeInOutQuart']}
            duration={[450, 450]}
        >
            {children}
        </QueueAnim>
    )
}
