import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import {createStore, applyMiddleware, compose} from "redux";
import thunk from "redux-thunk";
import {Provider} from "react-redux";
import {QueryClientProvider, QueryClient} from "react-query";

import rootReducer from "./redux/reducers";
import reportWebVitals from "./reportWebVitals";

import {ReactQueryDevtools} from "react-query/devtools";

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.js';

import './config/i18n/config';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const isDev = process.env.NODE_ENV === 'development';
const store = createStore(rootReducer, isDev ? composeEnhancers(applyMiddleware(thunk)) : applyMiddleware(thunk));

const queryClient = new QueryClient({
    defaultOptions: {
        onError: (error) => {
            console.log('Error loading data', error)
            if (error.response.status === 401) {
                window.location.href = '/login';
            }
        },
    }
});

ReactDOM.render(
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <Provider store={store}>
                <App/>
            </Provider>
            <ReactQueryDevtools initialIsOpen={false}/>
        </QueryClientProvider>
    </React.StrictMode>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
