import Skeletons from "../skeletons";
import {Card, Col, Row} from "antd";
import MyQueueMotion from "./Home/MyQueueMotion";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {browserName, isMobile} from "react-device-detect";
import {useHistory} from "react-router-dom";

const deg2rad = require('deg2rad')

export default ({healthcenters, isLoading}) => {

    if (isLoading) {
        return <Skeletons isLoading={isLoading}/>
    }

    const {t, i18n} = useTranslation();
    const [position, setPosition] = useState({latitude: 0, longitude: 0});
    const navigation = useHistory();

    useEffect(() => {
        if (navigator.geolocation) {
            navigator.permissions.query({name: 'geolocation'}).then(permissionStatus => {

                if (permissionStatus.state === 'denied') {
                    // alert('Please allow location access.');
                    // open app-settings:location for browser by browserName
                    // window.location.href = browserName === 'Chrome' ? 'chrome://settings/content/location' : 'app-settings:location';
                } else {
                    navigator.geolocation.getCurrentPosition(function (position) {
                        setPosition({
                            latitude: position.coords.latitude,
                            longitude: position.coords.longitude,
                        });
                    });
                }
            });
        } else {
            console.log("Geolocation is not available in your browser.");
        }

    }, []);

    const healthCenterHoursFormat = (workingHours) => {

        if (workingHours.length === 0) return i18n.language === 'ar' ? 'لا يوجد ساعات عمل' : 'No working hours';

        if (Number(workingHours) === 7) {
            if (i18n.language === 'en' || 'en-US') return '7:00 am to 2:00 pm';
            if (i18n.language === 'ar') return '7:00 صباحاً إلى 2:00 مساءً';
        }

        if (Number(workingHours) === Number(14)) {
            if (i18n.language === 'en' || 'en-US') return '07:00 am to 9:00 pm';
            if (i18n.language === 'ar') return '07:00 صباحاً إلى 9:00 مساءً';
        }

        if (Number(workingHours) === 17) {
            if (i18n.language === 'en' || 'en-US') return '07:00 am to 12:00 am';
            if (i18n.language === 'ar') return '07:00 صباحاً إلى 12:00 صباحاً';
        }

        if (Number(workingHours) === 24) return i18n.language === 'ar' ? '24 ساعة' : '24 hours';
    }

    const healthCenterDaysFormat = (weekendDuty) => {

        if (weekendDuty === "yes") {
            return i18n.language === 'ar' ? 'طوال ايام الاسبوع' : 'All days of the week';
        } else {
            return i18n.language === 'ar' ? 'الأحد إلى الخميس' : 'Sunday to Thursday';
        }
    }

    const isOpen = (index, workingHours) => {

        const opeHours = Array.from({length: Number(workingHours)}, (v, k) => k + 1);

        // check if current time is in the range of working hours
        return opeHours.includes(new Date().getHours());
    }

    const rowStyle = () => {

        const mainStyle = {
            backgroundColor: "#f0f0f0",
            padding: "10px",
            paddingRight: "11px",
            paddingBottom: "20px",
            borderRadius: "10px",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
        }

        if (isMobile) {
            return {
                ...mainStyle,
                alignItems: "center",
                justifyContent: "center",
                display: "flex",
                flexDirection: "column",
                flexWrap: "nowrap"
            }
        }

        return mainStyle;
    }

    const getDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371; // Radius of the earth in km
        const dLat = deg2rad(lat2 - lat1);  // deg2rad below
        const dLon = deg2rad(lon2 - lon1);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) * Math.sin(dLon / 2)
        ;
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c; // Distance in km

        // return without decimals point
        return Math.round(d);
    }

    const healthCenterItem = (item, index) => {

        if (index === 0) {
            console.log(item)
        }

        const isOpening = isOpen(index, item.workingHours);

        // check item centerName charecters length
        const longText = item.centerName.nameEng.length > 55;

        return (
            <Col
                span={isMobile ? 24 : 8}
                key={index}
            >
                <MyQueueMotion
                    key={index}
                    index={index}
                >
                    <Card
                        className={"health-center-hover"}
                        style={{
                            width: 300,
                            marginTop: 16,
                            borderRadius: 8,
                            minHeight: isMobile ? 0 : 235,
                        }}
                        loading={isLoading}
                        onClick={() => navigation.push('/health-center/' + item.centerName.nameEng.toLowerCase())}
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <div
                                style={{
                                    backgroundColor: "rgba(28,53,100,0.5)",
                                    padding: "5px",
                                    borderRadius: "5px",
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <span
                                    style={{
                                        color: 'white',
                                        fontWeight: "bold",
                                    }}
                                >
                                    {isOpening ? 'Open' : 'Closed'}
                                </span>
                            </div>
                            {
                                position.latitude !== 0 && position.longitude !== 0 && item.healthCenterInformation.coordinates.latitude !== 0 && item.healthCenterInformation.coordinates.longitude !== 0 &&
                                <p
                                    style={{
                                        color: "#1c3564",
                                        fontWeight: "bold",
                                    }}
                                >
                                    {getDistance(
                                        position.latitude,
                                        position.longitude,
                                        item.healthCenterInformation.coordinates.latitude,
                                        item.healthCenterInformation.coordinates.longitude
                                    ).toFixed(2)} km
                                </p>
                            }
                        </div>
                        <h4
                            style={{
                                color: "#1c3564",
                                fontWeight: "bold",
                                fontSize: longText ? "1.2rem" : "1.4rem",
                                marginTop: "35px",
                            }}
                        >
                            {item.centerName.nameEng}
                        </h4>
                    </Card>
                </MyQueueMotion>
            </Col>
        )
    }

    return (
        <Row
            gutter={isMobile ? 8 : 16}
            style={rowStyle()}
        >
            {
                healthcenters && healthcenters.map((item, index) => {
                    return healthCenterItem(item, index);
                })
            }
        </Row>
    )
}
