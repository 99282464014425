import axios from 'axios';

//Create axios client, pre-configured with baseURL
const service = axios.create({
    baseURL: process.env.REACT_APP_PORTAL_BASE_URL,
    timeout: 10000,
});

// Set JSON Web Token in Client to be included in all calls
export const setClientToken = (notUsed) => {
    service.interceptors.request
        .use(function (config) {
            const token = localStorage.getItem('token');
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }

            return config;
        });
}

export default service;
