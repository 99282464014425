import React from "react";

const HeaderComponent = ({ section }) => {
  return <section id="header"></section>;
};

export default HeaderComponent;

{
  /* <div className="mycontainer">
<div className="container">
    <div className="row">
        <div className="col-12 col-sm-12 col-md-6 d-flex justify-content-center"><img className="pulse animated" id="hero_logo" src="/assets/img/phc_white_logo.jpg" /></div>
        <div className="col">
            <div className="text-center text-sm-center text-md-start align-content-center">
                <h1 className="hero_text"><br /><strong>Central Directorate Of Primary Health Care</strong><br /></h1>
                <p id="hero_paragraph">portal.cdphc.com</p><button className="btn light_btn" type="button">CDPHC@MOH.GOV.KW</button>
            </div>
        </div>
    </div>
</div>
</div> */
}
