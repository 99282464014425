import {useSelector} from "react-redux";
import {Redirect} from "react-router";
import NavComponent from "../common/nav";
import HeaderComponent from "../common/header";
import FooterComponent from "../common/footer";
import React from "react";
import {useTranslation} from "react-i18next";
import WhoWeAreComponent from "../common/whoweare";
import StatsComponent from "../common/stats";

const StatsPage = () => {

    const userReducer = useSelector(reducer => reducer.userReducer);

    const {i18n, t} = useTranslation();

    return (
        <>
            <NavComponent/>
            <HeaderComponent section="Stats"/>
            <section>
                <div
                    className="mycontainer"
                    dir={i18n.language === "ar" ? "rtl" : "ltr"}
                >
                    <h1
                        id={"whoWeAre-title"}
                        className="hero_text hero_text_align_center"
                        style={{
                            marginBottom: "80px",
                            fontFamily: "Montserrat, sans-serif",
                            fontWeight: 800,
                            textAlign: i18n.language === "ar" ? "right" : "left",
                            fontSize: "2em",
                            fontOpticalSizing: "auto",
                            fontStyle: "normal",
                            color: "white",
                            marginTop: "50px",
                            marginLeft: i18n.language === "ar" ? "0" : "20",
                            marginRight: i18n.language === "ar" ? "20" : "0",
                        }}
                    >
                        {t("views.stats.title")}
                    </h1>

                    <StatsComponent/>
                </div>
            </section>
            <FooterComponent/>
        </>
    )
}

export default StatsPage;
