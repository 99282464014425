import NavComponent from "../common/nav";
import FooterComponent from "../common/footer";
import React from "react";
import NotFoundComponent from "../common/not-found";

const NotFoundPage = () => {

    return (
        <>
            <NavComponent/>
            <section>
                <div className="mycontainer">
                    <NotFoundComponent/>
                </div>
            </section>
            <FooterComponent/>
        </>
    )
}

export default NotFoundPage;
