import React, {useEffect, useState} from "react";
import NavComponent from "../common/nav";
import HeaderComponent from "../common/header";
import FooterComponent from "../common/footer";
import {useDispatch, useSelector} from "react-redux";
import {Redirect, useParams} from "react-router";
import {useTranslation} from "react-i18next";
import {useQuery} from "react-query";
import service from "../../config/service";
import Healthareas from "../common/healtharea";
import {Button, Input} from "antd";
import {LeftOutlined, RightOutlined, SearchOutlined} from "@ant-design/icons";
import {isMobile} from "react-device-detect";
import useWindowDimensions from "../../utils/utils";

export const HealthAreaPage = () => {

    const {t} = useTranslation();
    const [mainData, setMainData] = useState([]);
    const [healthCenters, setHealthCenters] = useState([]);
    const [filteredHealthCenters, setFilteredHealthCenters] = useState([]);

    const {healthArea} = useParams();
    const {i18n} = useTranslation();

    const {height, width} = useWindowDimensions();
    const [searchValue, setSearchValue] = useState('');
    const [isSearching, setIsSearching] = useState(false);

    const {data, isError, isLoading} = useQuery('healthCenterService', async () => {
        const res = await service.get('/healthCenterService');
        return res.data;
    }, {refetchOnWindowFocus: false});

    const healthAreaLocalization = [{
        key: 'Ahmadi Health Area', en: "Ahmadi Health Area", ar: "منطقة الأحمدي الصحية"
    }, {
        key: 'Capital Health Area', en: "Capital Health Area", ar: "منطقة العاصمة الصحية"
    }, {
        key: 'Farwaniya Health Area', en: "Farwaniya Health Area", ar: "منطقة الفروانية الصحية"
    }, {
        key: 'Hawalli Health Area', en: "Hawalli Health Area", ar: "منطقة حولي الصحية"
    }, {
        key: 'Jahra Health Area', en: "Jahra Health Area", ar: "منطقة الجهراء الصحية"
    }, {
        key: 'Mubarak AlKabeer Health Area', en: "Mubarak Al Kabeer Health Area", ar: "منطقة مبارك الكبير الصحية"
    }]

    useEffect(() => {

        if (data) {
            setMainData(data);
        }

        const getData = async () => {
            new Promise((resolve, reject) => {
                try {
                    const d = [];
                    const dha = [];
                    const dhc = [];

                    // for each key obj ket in data convert to array and add to healthCenterData
                    data && Object.keys(data).forEach((key, index) => {
                        d.push({
                            key: index,
                            title: healthAreaLocalization.find((h) => h.key === key),
                            label: healthAreaLocalization.find((h) => h.key === key),
                            items: data[key]
                        });
                        dha.push({
                            key: String(index), label: healthAreaLocalization.find((h) => h.key === key),
                        });
                        dhc[index] = '-1';
                    });
                    resolve({d, dha, dhc});
                } catch (e) {
                    reject(e);
                }
            }).then(({d, dha, dhc}) => {
                /*
                * TODO: check the array elements change so many time
                *  so the problem the elements change so many time and the value not equel what clicked and the healthCenter showing different value
                * */

                if (healthArea) {

                    let healthAreaIndex = -1;
                    for (let i = 0; i < dha.length; i++) {
                        if (dha[i].label.en.toLowerCase() === healthArea || dha[i].ar === healthArea) {
                            healthAreaIndex = i;
                            break;
                        }
                    }

                    // if healthArea is not in the list redirect to 404
                    if (healthAreaIndex === -1) {
                        return <Redirect to="/not-found"/>
                    }

                    if (d[healthAreaIndex]) {
                        setHealthCenters(d[healthAreaIndex].items);
                        setFilteredHealthCenters(d[healthAreaIndex].items);
                    }
                } else {
                    // if healthArea is not in the list redirect to 404
                    return <Redirect to="/not-found"/>
                }
            });
        }

        getData();

        return () => {
            getData();
        }
    }, [healthArea, data]);

    const capitalizeTitle = (title) => {
        // capitalize first letter of each word
        return title.split(' ').map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        }).join('\n');
    }

    const onSearch = (value) => {
        if (value) {
            setIsSearching(true);
            setSearchValue(value);
            const filtered = healthCenters.filter((hc) => {
                return hc.centerName.nameEng.toLowerCase().includes(value.toLowerCase()) ||
                    hc.centerName.nameArb.includes(value);
            });
            setFilteredHealthCenters(filtered);
        } else {
            setIsSearching(false);
            setSearchValue('');
            setFilteredHealthCenters(healthCenters);
        }
    }

    return (<>
        <NavComponent/>
        <HeaderComponent section="Guidelines"/>
        <section>
            <div className="mycontainer">
                <div
                    style={{
                        padding: '16px 8px',
                    }}
                >
                    <Button
                        icon={i18n.dir() === 'rtl' ? <RightOutlined/> : <LeftOutlined/>}
                        size='middle'
                        onClick={() => window.history.back()}
                    />
                    <h1
                        id={"health-area-title"}
                        className="hero_text hero_text_align_center"
                        style={{
                            marginBottom: "80px",
                            textAlign: "left",
                            fontFamily: "Montserrat, sans-serif",
                            fontWeight: 800,
                            fontOpticalSizing: "auto",
                            fontStyle: "normal",
                            textShadow: "0 0 5px #1c3564",
                        }}
                    >
                        {capitalizeTitle(healthArea)}
                    </h1>
                </div>

                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        width: '100%',
                        marginBottom: '40px',
                    }}
                >
                    {/* create html input for searching with style border and shadow and add search button*/}
                    <div
                        style={{
                            position: 'relative',
                            width: isMobile ? width * 0.75 : width * 0.35,
                            backgroundColor: '#fff',
                            boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
                            borderRadius: '10px',
                            marginTop: '10px',
                            height: '40px',
                        }}
                    >
                        <Input
                            placeholder={t('home.search')}
                            style={{
                                width: isMobile ? width * 0.75 : width * 0.35,
                                backgroundColor: '#fff',
                                border: '1px solid #d9d9d9',
                                boxShadow: '0 2px 4px 0 rgba(0,0,0,0.2)',
                                borderRadius: '10px',
                                height: '100%',
                            }}
                            value={searchValue}
                            onChange={(e) => onSearch(e.target.value)}
                        />
                        {/*search button icon*/}
                        <Button
                            icon={
                                <SearchOutlined
                                    style={{
                                        backgroundColor: '#1c3564',
                                        height: '100%',
                                        color: '#fff',
                                        borderRadius: '0 8px 8px 0',
                                    }}
                                />
                            }
                            style={{
                                position: 'absolute',
                                right: '0',
                                top: '0',
                                borderRadius: '0 10px 10px 0',
                                height: '100%',
                                padding: '0',
                            }}
                            onClick={() => onSearch(searchValue)}
                        />
                    </div>
                </div>

                <Healthareas healthcenters={filteredHealthCenters} isLoading={isLoading}/>
            </div>
        </section>
        <FooterComponent/>
    </>)
}

export default HealthAreaPage;
