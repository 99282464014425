import {Button, Col, Menu, Row, Tooltip} from "antd";
import {useTranslation} from "react-i18next";
import {Input, Space} from 'antd';
import useWindowDimensions from "../../../utils/utils";
import {useEffect, useRef, useState} from "react";
import {FilterOutlined} from "@ant-design/icons";
import MyQueueMotion from "./MyQueueMotion";
import {setSelectHealthArea} from "../../../redux/actions/userActions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";

const QueueAnim = require('rc-queue-anim').default;

const DesktopView = (props) => {

    const {
        healthCenterData,
        selectedHealthArea,
        selectedHealthCenter,
        onSelectHealthArea,
        onSelectHealthCenter,
        healthAreaMenuStyle,
        isSearch,
        filteredHealthCenters
    } = props;

    const dispatch = useDispatch();
    const {i18n} = useTranslation();
    const isMounted = useRef(true);
    const navigation = useHistory();

    useEffect(() => {

        if (healthCenterData.length > 0) {
            if (isMounted.current) {
                isMounted.current = true;
            }
        }

        return () => {
            isMounted.current = false;
        };
    }, []);

    const healthCenterRender = (item, index) => {
        return (
            <Menu.Item
                key={index}
                style={i18n.language === 'ar' ? {textAlign: 'right'} : {textAlign: 'left'}}
            >
                {i18n.language === 'ar' ? item.centerName.nameArb : item.centerName.nameEng}
            </Menu.Item>
        );
    }

    return (
        <div>
            <Row
                style={{
                    marginTop: '40px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                {
                    !isSearch && (
                        <Col
                            style={{height: '100%', position: 'relative'}}
                        >
                            {/*<Menu*/}
                            {/*    defaultOpenKeys={[selectedHealthArea]}*/}
                            {/*    selectedKeys={[selectedHealthArea]}*/}
                            {/*    onSelect={onSelectHealthArea}*/}
                            {/*    mode={healthAreaMenuStyle}*/}
                            {/*>*/}
                            {/*    {*/}
                            {/*        healthCenterData.map((item, index) => {*/}
                            {/*            return (*/}
                            {/*                <Menu.Item*/}
                            {/*                    key={index}*/}
                            {/*                    style={i18n.language === 'ar' ? {textAlign: 'right'} : {textAlign: 'left'}}*/}
                            {/*                >*/}
                            {/*                    {i18n.language === 'ar' ? item.title.ar : item.title.en}*/}
                            {/*                </Menu.Item>*/}
                            {/*            )*/}
                            {/*        })*/}
                            {/*    }*/}
                            {/*</Menu>*/}
                            <QueueAnim
                                component={Row}
                                type={'bottom'}
                                duration={500}
                                style={{
                                    marginTop: '40px',
                                    width: '100%',
                                }}
                            >

                                <div
                                    key={'healthArea'}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        flexDirection: 'row',
                                        flexWrap: 'wrap',
                                        width: '100%',
                                        height: '100%',
                                        gap: '25px',
                                    }}
                                >
                                    {
                                        healthCenterData
                                            .map((item, index) => {
                                                return (
                                                    <div
                                                        className={"health-area-hover"}
                                                        key={index}
                                                        style={{
                                                            width: '250px',
                                                            height: '100px',
                                                            border: '1px solid #f0f0f0',
                                                            borderRadius: '10px',
                                                            backgroundColor: '#fff',
                                                            padding: '10px',
                                                        }}
                                                        onClick={() => {
                                                            navigation.push('/health-area/' + item.title.en.toLowerCase())
                                                        }}
                                                    >
                                                        <h4
                                                            className={"my-font"}
                                                            style={{
                                                                textAlign: "left",
                                                            }}
                                                        >
                                                            {i18n.language === 'ar' ? item.title.ar : item.title.en}
                                                        </h4>
                                                    </div>
                                                )
                                            })
                                    }
                                </div>
                            </QueueAnim>
                        </Col>
                    )
                }
                {/*<Col*/}
                {/*    style={{*/}
                {/*        width: '600px',*/}
                {/*        height: '300px',*/}
                {/*        overflowY: 'scroll',*/}
                {/*        position: 'relative',*/}
                {/*    }}*/}
                {/*>*/}
                {/*    <Menu*/}
                {/*        mode="inline"*/}
                {/*        defaultOpenKeys={[selectedHealthCenter[selectedHealthArea]]}*/}
                {/*        selectedKeys={[selectedHealthCenter[selectedHealthArea]]}*/}
                {/*        onSelect={onSelectHealthCenter}*/}
                {/*    >*/}
                {/*        {*/}
                {/*            healthCenterData[selectedHealthArea] && !isSearch && healthCenterData[selectedHealthArea].items.map(healthCenterRender)*/}
                {/*        }*/}
                {/*        {*/}
                {/*            isSearch && filteredHealthCenters.map(healthCenterRender)*/}
                {/*        }*/}
                {/*    </Menu>*/}
                {/*</Col>*/}
            </Row>
        </div>
    );
}

export default DesktopView;
