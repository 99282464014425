import {useSelector} from "react-redux";
import {Redirect} from "react-router";
import NavComponent from "../common/nav";
import HeaderComponent from "../common/header";
import FooterComponent from "../common/footer";
import React from "react";
import GuidelinesComponent from "../common/guidelines";
import {useTranslation} from "react-i18next";

const GuidelinesPage = () => {

    const userReducer = useSelector(reducer => reducer.userReducer);

    const {t} = useTranslation();

    if (!userReducer.loggedIn)
        return <Redirect to={"/login/guidelines"}/>

    return (
        <>
            <NavComponent/>
            <HeaderComponent section="Guidelines"/>
            <section>
                <div className="mycontainer">
                    <h1 className="hero_text hero_text_align_center" style={{color: 'white'}}>
                        {t("views.documents.guidelines")}
                    </h1>
                    <GuidelinesComponent/>
                </div>
            </section>
            <FooterComponent />
        </>
    )

}

export default GuidelinesPage;
