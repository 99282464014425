import {useQuery} from "react-query";
import service from "../../config/service";
import {Alert, Col, Layout, Row, Spin} from "antd";
import Documents from "./Documents";
import React from "react";
import {useTranslation} from "react-i18next";
import useWindowDimensions from "../../utils/utils";
import {useDispatch} from "react-redux";
import {logoutUser} from "../../redux/actions/userActions";
import {Redirect} from "react-router";

const WhoWeAreComponent = () => {

    const {height, width} = useWindowDimensions();
    const dispatch = useDispatch();
    const {i18n, t} = useTranslation();

    const handleLocaleChange = locale => {
        // Handle locale change...

        document.getElementsByTagName("html")[0].dir = i18n.language === "ar" ? "rtl" : "ltr";
    }

    return (
        <Row
            dir={i18n.language === "ar" ? "rtl" : "ltr"}
            style={{
                backgroundColor: "white",
                marginTop: "50px",
                marginBottom: "50px",
                padding: "20px",
                borderRadius: "10px",
                boxShadow: "0px 0px 3px 0px #000000",
                direction: i18n.language === "ar" ? "rtl" : "ltr",
            }}
            className="mycontainer"
        >
            <Col>
                <p
                    dir={i18n.language === "ar" ? "rtl" : "ltr"}
                    style={{...styles(i18n).paragraph, ...styles(i18n).textAlignLocalization}}
                >
                    {t("views.whoWeAre.whoWeAre.p1")}
                </p>
                <p
                    dir={i18n.language === "ar" ? "rtl" : "ltr"}
                    style={styles(i18n).paragraph}
                >
                    {t("views.whoWeAre.whoWeAre.p2")}
                </p>
                <p
                    dir={i18n.language === "ar" ? "rtl" : "ltr"}
                    style={styles(i18n).paragraph}
                >
                    {t("views.whoWeAre.whoWeAre.p3")}
                </p>
                <p
                    dir={i18n.language === "ar" ? "rtl" : "ltr"}
                    style={styles(i18n).paragraph}
                >
                    {t("views.whoWeAre.whoWeAre.p4")}
                </p>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        marginTop: "50px",
                        marginBottom: "20px",
                    }}
                >
                    <img
                        src="./assets/img/clinic-map.png"
                        alt="CDPHC Logo"
                        className={"img-fluid"}
                    />
                </div>
                <div
                    style={{
                        marginTop: "50px",
                    }}
                >
                    <p
                        dir={i18n.language === "ar" ? "rtl" : "ltr"}
                        style={styles(i18n).paragraph}
                    >
                        {t("views.whoWeAre.p5.title")}
                    </p>
                    <ol
                        dir={i18n.language === "ar" ? "rtl" : "ltr"}
                        style={styles(i18n).paragraph}
                    >
                        <li>{t("views.whoWeAre.p5.list.item1")}</li>
                        <li>{t("views.whoWeAre.p5.list.item2")}</li>
                        <li>{t("views.whoWeAre.p5.list.item3")}</li>
                        <li>{t("views.whoWeAre.p5.list.item4")}</li>
                        <li>{t("views.whoWeAre.p5.list.item5")}</li>
                        <li>{t("views.whoWeAre.p5.list.item6")}</li>
                        <li>{t("views.whoWeAre.p5.list.item7")}</li>
                        <li>{t("views.whoWeAre.p5.list.item8")}</li>
                        <li>{t("views.whoWeAre.p5.list.item9")}</li>
                        <li>{t("views.whoWeAre.p5.list.item10")}</li>
                    </ol>
                </div>
                <div
                    style={{
                        marginTop: "50px",
                    }}
                >
                    <p
                        dir={i18n.language === "ar" ? "rtl" : "ltr"}
                        style={styles(i18n).paragraph}
                    >
                        {t("views.whoWeAre.p6.title")}
                    </p>
                    <ol
                        dir={i18n.language === "ar" ? "rtl" : "ltr"}
                        style={styles(i18n).paragraph}
                    >
                        <li>{t("views.whoWeAre.p6.list.item1")}</li>
                        <li>{t("views.whoWeAre.p6.list.item2")}</li>
                        <li>{t("views.whoWeAre.p6.list.item3")}</li>
                        <li>{t("views.whoWeAre.p6.list.item4")}</li>
                        <li>{t("views.whoWeAre.p6.list.item5")}</li>
                        <li>{t("views.whoWeAre.p6.list.item6")}</li>
                        <li>{t("views.whoWeAre.p6.list.item7")}</li>
                        <li>{t("views.whoWeAre.p6.list.item8")}</li>
                        <li>{t("views.whoWeAre.p6.list.item9")}</li>
                        <li>{t("views.whoWeAre.p6.list.item10")}</li>
                        <li>{t("views.whoWeAre.p6.list.item11")}</li>
                        <li>{t("views.whoWeAre.p6.list.item12")}</li>
                    </ol>
                </div>
                <div
                    style={{
                        textAlign: "center",
                        marginTop: "40px",
                    }}
                >
                    <p
                        style={{
                            ...styles(i18n).paragraph,
                            fontSize: "1.5em",
                            color: '#022859',
                            fontWeight: 800,
                        }}
                    >
                        {t("views.whoWeAre.vision.title")}
                    </p>
                    <p
                        dir={i18n.language === "ar" ? "rtl" : "ltr"}
                        style={{...styles(i18n).paragraph}}
                    >
                        {t("views.whoWeAre.vision.subtitle.p1")}
                    </p>
                    <p
                        dir={i18n.language === "ar" ? "rtl" : "ltr"}
                        style={{...styles(i18n).paragraph}}
                    >
                        {t("views.whoWeAre.vision.subtitle.p2")}
                    </p>
                </div>
                <div
                    style={{
                        marginTop: "40px",
                    }}
                >
                    <p
                        style={{
                            ...styles(i18n).paragraph,
                            fontSize: "1.5em",
                            color: '#022859',
                            fontWeight: 800,
                        }}
                    >
                        {t("views.whoWeAre.mission.title")}
                    </p>
                    <p
                        dir={i18n.language === "ar" ? "rtl" : "ltr"}
                        style={{...styles(i18n).paragraph}}
                    >
                        {t("views.whoWeAre.mission.subtitle.p1")}
                    </p>
                    <p
                        dir={i18n.language === "ar" ? "rtl" : "ltr"}
                        style={{...styles(i18n).paragraph}}
                    >
                        {t("views.whoWeAre.mission.subtitle.p2")}
                    </p>
                    <p
                        dir={i18n.language === "ar" ? "rtl" : "ltr"}
                        style={{...styles(i18n).paragraph}}
                    >
                        {t("views.whoWeAre.mission.subtitle.p3")}
                    </p>
                    <p
                        dir={i18n.language === "ar" ? "rtl" : "ltr"}
                        style={{...styles(i18n).paragraph}}
                    >
                        {t("views.whoWeAre.mission.subtitle.p4")}
                    </p>
                    <p
                        dir={i18n.language === "ar" ? "rtl" : "ltr"}
                        style={{...styles(i18n).paragraph}}
                    >
                        {t("views.whoWeAre.mission.subtitle.p5")}
                    </p>
                    <p
                        dir={i18n.language === "ar" ? "rtl" : "ltr"}
                        style={{...styles(i18n).paragraph}}
                    >
                        {t("views.whoWeAre.mission.subtitle.p6")}
                    </p>
                </div>
                <div
                    style={{
                        marginTop: "40px",
                    }}
                >
                    <p
                        style={{
                            ...styles(i18n).paragraph,
                            fontSize: "1.5em",
                            color: '#022859',
                            textAlign: "center",
                            fontWeight: 800
                        }}
                    >
                        {t("views.whoWeAre.values.title")}
                    </p>
                    <p
                        dir={i18n.language === "ar" ? "rtl" : "ltr"}
                        style={{...styles(i18n).paragraph, textAlign: "center"}}
                    >
                        {t("views.whoWeAre.values.subtitle")}
                    </p>
                    <div
                        style={{
                            display: "flex",
                            justifyContent: "space-around",
                            flexWrap: "wrap",
                            marginTop: "20px",
                            marginBottom: "20px",
                        }}
                    >
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                border: "1px solid #000000",
                            }}
                        >
                            <div
                                style={{
                                    borderBottom: "1px solid #000000",
                                    width: "100%",
                                    backgroundColor: "#1B3659",
                                }}
                            >
                                <p
                                    dir={i18n.language === "ar" ? "rtl" : "ltr"}
                                    style={{
                                        ...styles(i18n).paragraph,
                                        ...styles(i18n).whiteText,
                                        textAlign: "center",
                                        margin: 0,
                                    }}
                                >
                                    {t("views.whoWeAre.values.description.p1.title")}
                                </p>
                            </div>
                            <p
                                dir={i18n.language === "ar" ? "rtl" : "ltr"}
                                style={{...styles(i18n).paragraph, textAlign: "center"}}
                            >
                                {t("views.whoWeAre.values.description.p1.description")}
                            </p>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                border: "1px solid #000000",
                            }}
                        >
                            <div
                                style={{
                                    borderBottom: "1px solid #000000",
                                    width: "100%",
                                    backgroundColor: "#256B93",
                                }}
                            >
                                <p
                                    dir={i18n.language === "ar" ? "rtl" : "ltr"}
                                    style={{
                                        ...styles(i18n).paragraph,
                                        ...styles(i18n).whiteText,
                                        textAlign: "center",
                                        margin: 0,
                                    }}
                                >
                                    {t("views.whoWeAre.values.description.p2.title")}
                                </p>
                            </div>
                            <p
                                dir={i18n.language === "ar" ? "rtl" : "ltr"}
                                style={{...styles(i18n).paragraph, textAlign: "center"}}
                            >
                                {t("views.whoWeAre.values.description.p2.description")}
                            </p>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                border: "1px solid #000000",
                            }}
                        >
                            <div
                                style={{
                                    borderBottom: "1px solid #000000",
                                    width: "100%",
                                    backgroundColor: "#4F8DB7",
                                }}
                            >
                                <p
                                    dir={i18n.language === "ar" ? "rtl" : "ltr"}
                                    style={{
                                        ...styles(i18n).paragraph,
                                        ...styles(i18n).whiteText,
                                        textAlign: "center",
                                        margin: 0,
                                    }}
                                >
                                    {t("views.whoWeAre.values.description.p3.title")}
                                </p>
                            </div>
                            <p
                                dir={i18n.language === "ar" ? "rtl" : "ltr"}
                                style={{...styles(i18n).paragraph, textAlign: "center"}}
                            >
                                {t("views.whoWeAre.values.description.p3.description")}
                            </p>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                border: "1px solid #000000",
                            }}
                        >
                            <div
                                style={{
                                    borderBottom: "1px solid #000000",
                                    width: "100%",
                                    backgroundColor: "#529AD9",
                                }}
                            >
                                <p
                                    dir={i18n.language === "ar" ? "rtl" : "ltr"}
                                    style={{
                                        ...styles(i18n).paragraph,
                                        ...styles(i18n).whiteText,
                                        textAlign: "center",
                                        margin: 0,
                                    }}
                                >
                                    {t("views.whoWeAre.values.description.p4.title")}
                                </p>
                            </div>
                            <p
                                dir={i18n.language === "ar" ? "rtl" : "ltr"}
                                style={{...styles(i18n).paragraph, textAlign: "center"}}
                            >
                                {t("views.whoWeAre.values.description.p4.description")}
                            </p>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                border: "1px solid #000000",
                            }}
                        >
                            <div
                                style={{
                                    borderBottom: "1px solid #000000",
                                    width: "100%",
                                    backgroundColor: "#0477BF",
                                }}
                            >
                                <p
                                    dir={i18n.language === "ar" ? "rtl" : "ltr"}
                                    style={{
                                        ...styles(i18n).paragraph,
                                        ...styles(i18n).whiteText,
                                        textAlign: "center",
                                        margin: 0,
                                    }}
                                >
                                    {t("views.whoWeAre.values.description.p5.title")}
                                </p>
                            </div>
                            <p
                                dir={i18n.language === "ar" ? "rtl" : "ltr"}
                                style={{...styles(i18n).paragraph, textAlign: "center"}}
                            >
                                {t("views.whoWeAre.values.description.p5.description")}
                            </p>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                border: "1px solid #000000",
                            }}
                        >
                            <div
                                style={{
                                    borderBottom: "1px solid #000000",
                                    width: "100%",
                                    backgroundColor: "#1B3659"
                                }}
                            >
                                <p
                                    dir={i18n.language === "ar" ? "rtl" : "ltr"}
                                    style={{
                                        ...styles(i18n).paragraph,
                                        ...styles(i18n).whiteText,
                                        textAlign: "center",
                                        margin: 0,
                                    }}
                                >
                                    {t("views.whoWeAre.values.description.p6.title")}
                                </p>
                            </div>
                            <p
                                dir={i18n.language === "ar" ? "rtl" : "ltr"}
                                style={{...styles(i18n).paragraph, textAlign: "center"}}
                            >
                                {t("views.whoWeAre.values.description.p6.description")}
                            </p>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center",
                                width: "100%",
                                border: "1px solid #000000",
                            }}
                        >
                            <div
                                style={{
                                    borderBottom: "1px solid #000000",
                                    width: "100%",
                                    backgroundColor: "#0477BF",
                                }}
                            >
                                <p
                                    dir={i18n.language === "ar" ? "rtl" : "ltr"}
                                    style={{
                                        ...styles(i18n).paragraph,
                                        ...styles(i18n).whiteText,
                                        textAlign: "center",
                                        margin: 0,
                                    }}
                                >
                                    {t("views.whoWeAre.values.description.p7.title")}
                                </p>
                            </div>
                            <p
                                dir={i18n.language === "ar" ? "rtl" : "ltr"}
                                style={{...styles(i18n).paragraph, textAlign: "center"}}
                            >
                                {t("views.whoWeAre.values.description.p7.description")}
                            </p>
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
    );
}

const styles = (i18n) => {
    return {
        container: {
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
            backgroundColor: "#1c3564",
        },
        logo: {
            width: "180px",
            borderRadius: "50%",
        },
        title: {
            marginBottom: "80px",
            textAlign: "left",
            fontFamily: "Montserrat, sans-serif",
            fontWeight: 800,
            fontSize: "2em",
            fontOpticalSizing: "auto",
            fontStyle: "normal",
            color: "white",
            marginTop: "50px",
        },
        paragraph: {
            fontFamily: "Montserrat, sans-serif",
            fontWeight: 400,
            fontSize: "1.2em",
            color: "#000000",
            textAlign: i18n.language === "ar" ? "right" : "left",
            lineHeight: "1.5em",
            direction: i18n.language === "ar" ? "rtl" : "ltr",
        },
        whiteText: {
            color: "white",
        },
        textAlignLocalization: {
            textAlign: i18n.language === "ar" ? "right" : "left",
        },
    }
}

export default WhoWeAreComponent;
