import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {BellOutlined, SettingFilled} from "@ant-design/icons";
import moment from "moment/moment";

export default () => {

    const {t, i18n} = useTranslation();

    const [currentLang, setCurrentLang] = React.useState(i18n.language);

    useEffect(() => {
        changeLanguage('ar')
    }, []);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setCurrentLang(i18n.language);
    }

    const langButtons = () => {

        return (
            <li
                className="nav-item"
            >
                {
                    currentLang === 'en' ?
                        <a
                            className="nav-link"
                            onClick={() => changeLanguage('ar')}
                        >
                            <p>اللغة العربية</p>
                        </a> :
                        <a
                            className="nav-link"
                            onClick={() => changeLanguage('en')}
                        >
                            <p>English</p>
                        </a>
                }
            </li>
        );
    }

    const notificationButton = () => {
        return (
            <li className="nav-item dropdown dropdown_header_custom">
                <a
                    className="nav-link dropdown-toggle dropdown_header_main"
                    href="#"
                    id="navbarDarkDropdownMenuNotification"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <BellOutlined/>
                </a>
                <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDarkDropdownMenuNotification"
                >
                    {
                        notificationsMessage.length > 0 &&
                        notificationsMessage.map((item, index) => {
                            return renderNotificationItem(item);
                        })
                    }
                </div>
            </li>
        )
    }

    const renderNotificationItem = (item) => {
        return (
            <div key={item.id} className="dropdown-item">
                <span><b>{item.title}</b></span><br/>
                <span>{item.body}</span><br/>
                <span
                    style={{fontSize: '12px', color: '#999999'}}
                >{moment(item.createdDate).format('DD MMMM yyyy')}</span><br/>
            </div>
        )
    }

    return (
        <section>
            <nav className="navbar navbar-dark navbar-expand-md navigation-clean-button" dir={i18n.dir()}>
                <div className="container">
                    <a className="nav-brand" href="/">
                        <img
                            src="./assets/img/kwt-logo.png"
                            alt="Kuwait Logo"
                            style={{
                                width: "200px",
                            }}
                        />
                    </a>
                    <button
                        data-toggle="collapse"
                        className="navbar-toggler"
                        data-target="#navcol-1"
                    >
                        {/*<span className="visually-hidden">Toggle navigation</span>*/}
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <h3
                        style={{
                            fontSize: "2em",
                            fontWeight: "bold",
                        }}
                    >
                        مراكز الرعاية الأولية
                    </h3>
                    <div className="collapse navbar-collapse" id="navcol-1">
                        <ul className="navbar-nav me-auto">
                            <li className="nav-item">
                                {langButtons()}
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </section>
    );
}
