import {useSelector} from "react-redux";
import {Redirect} from "react-router";
import NavComponent from "../common/nav";
import HeaderComponent from "../common/header";
import FooterComponent from "../common/footer";
import React from "react";
import BookletsComponent from "../common/booklets";
import ContactComponent from "../common/contact";

const ContactPage = () => {

    const userReducer = useSelector(reducer => reducer.userReducer);

    if (!userReducer.loggedIn)
        return <Redirect to={"/login/contact"}/>

    return (
        <>
            <NavComponent />
            <HeaderComponent section="Contact Us" />
            <section>
                <div className="mycontainer">
                    <ContactComponent />
                </div>
            </section>
            <FooterComponent />
        </>
    )
}

export default ContactPage;
