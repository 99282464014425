import { useState, useEffect } from 'react';

const development = process.env.REACT_APP_ENV === 'development';
const localit = process.env.REACT_APP_ENV === 'localit' | 'it';
const production = process.env.REACT_APP_ENV === 'production';

function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height
    };
}

export default function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimensions;
}

export const isDev = () => {
    return development;
}

export const isLocalIT = () => {
    return localit;
}

export const isProduction = () => {
    return production;
}
