import NavComponent from "../common/nav";
import ProfileComponent from "../common/profile";
import FooterComponent from "../common/footer";
import {useState} from "react";

const ProfilePage = () => {

    const [emptyValues, setEmptyValues] = useState(0);

    return (
        <>
            {/*<HeaderComponent section="profile" />*/}
            <NavComponent emptyValues={emptyValues} />
            <ProfileComponent setEmptyValues={setEmptyValues} />
            <FooterComponent />
        </>
    )
}

export default ProfilePage;
