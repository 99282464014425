import React from "react";
import {useTranslation} from "react-i18next";
import HealthCenterInformationComponent from "../common/healthCenterInfromation";
import Nav from "../common/healthCenterInfromation/nav";

export const HealthCenterInformationPage = () => {

    const {t} = useTranslation();

    return (
        <>
            <Nav/>
            <section>
                <HealthCenterInformationComponent/>
            </section>
        </>
    )
}

export default HealthCenterInformationPage;
