/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
// import CircularsComponent from "../common/circulars";
import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import NavComponent from "../common/nav";
import FooterComponent from "../common/footer";
import { Col, Row, Input, Spin, Table, Divider, Button } from "antd";
import { useSelector } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "react-query";
import service from "../../config/service";
import EventCard from "../common/eventCard";
import EmployeeSearh from "../common/EmployeeSearh";
import { json2csvAsync, json2csv } from "json-2-csv";
import fileDownload from "js-file-download";

const NominationPage = () => {
  const [employeeId, setEmployeeId] = useState(null);
  const [reload, setReload] = useState(false);
  const queryClient = useQueryClient();
  const userReducer = useSelector((reducer) => reducer.userReducer);
  const [activeSearch, setActiveSearch] = useState(true);
  const [summary, setSummary] = useState("");
  const eventId = window.location.href.substring(
    window.location.href.lastIndexOf("/") + 1
  );

  useEffect(() => {
    const eventId = window.location.href.substring(
      window.location.href.lastIndexOf("/") + 1
    );
    const fetcheEventDetails = async () => {
      axios
        .get(
          `https://www.googleapis.com/calendar/v3/calendars/ads9ovestqt8ungnvfe19re29c@group.calendar.google.com/events/${eventId}?key=AIzaSyDfHtUfh1CijqmBm29wsFMBBP0oR9gFAdM`
        )
        .then((resp) => {
          const eventDetails = resp.data;
          setSummary(eventDetails.summary);
        })
        .catch((error) => {

        });
    };
    fetcheEventDetails();
  });

  const eventsRegistrants = async () => {
    const res = await service.get("eventsRegistrants/" + eventId);

    setReload(false);
    return res.data;
  };

  const { data, isError, isLoading } = useQuery(
    ["nomination"],
    eventsRegistrants,
    { refetchOnWindowFocus: false }
  );

  if (!userReducer.loggedIn) return <Redirect to="/login/calendar" />;

  const columns = [
    {
      title: "File No.",
      dataIndex: "fileNo",
      key: "fileNo",
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "phone",
      dataIndex: "phoneNumber",
      key: "phone",
    },
    {
      title: "Health Area",
      dataIndex: "healthCenter",
      key: "healtharea",
    },
    {
      title: "Region",
      dataIndex: "healthArea",
      key: "Region",
    },
    {
      title: "Remove",
      dataIndex: "id",
      key: "id",
      value: "id",
      render: (id) => <a onClick={() => removeUser.mutate(id)}>delete</a>,
    },
  ];

  const onDownload = (name) => {
    let namedate = new Date().toDateString();
    const csv = json2csvAsync(data).then((result) => {
      fileDownload(result, `${name} - ${summary} - ${namedate}.csv`);
    });
  };

  const removeUser = useMutation(
    (id) => {
      let deleteURL = "eventsRegistrants/" + id;
      return service.delete(deleteURL).then((res) => {
        setReload(true);
      });
    },
    {
      onSuccess: () => queryClient.invalidateQueries("nomination"),
      onError: () => console.log("user cant be deleted"),
    }
  );

  const onSearch = (value) => {
    setEmployeeId(value);
    setActiveSearch(true);
  };
  return (
    <>
      <NavComponent />
      <section>
        <div className="mycontainer">
          <EventCard eventId={eventId} />
          <div className="currentNomination">
            <Divider plain>Search & Enroll an employee</Divider>
            <Row>
              <Col span={8}></Col>
              <Col span={8}>
                <Input.Search onSearch={onSearch}></Input.Search>
              </Col>
              <Col span={8}></Col>
            </Row>
            {employeeId > 0 ? (
              <EmployeeSearh
                callBack={setReload}
                eventId={eventId}
                employeeId={employeeId}
                setActiveSearch={[activeSearch, setActiveSearch]}
              ></EmployeeSearh>
            ) : (
              ""
            )}
          </div>
          <Divider>Current Nomination</Divider>
          <div>
            <Button onClick={() => onDownload("cadidate list")}>
              Download List
            </Button>
          </div>
          {reload == true ? (
            <Spin tip="Loading...">
              {" "}
              <Table
                className="nominationTable"
                dataSource={data}
                columns={columns}
              />
            </Spin>
          ) : (
            <div>
              <Table
                className="nominationTable"
                dataSource={data}
                columns={columns}
              />
            </div>
          )}
          ;
        </div>
      </section>

      <FooterComponent />
    </>
  );
};

export default NominationPage;
