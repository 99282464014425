import React, {useState} from "react";
import {Card, Col, Row} from "antd";
import MyQueueMotion from "./common/Home/MyQueueMotion";
import {isMobile} from "react-device-detect";

export default ({isLoading, healthCenterView}) => {

    const [skeletonItems, setSkeletonItems] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

    const skeletonItem = (index) => {
        return (
            <Col span={isMobile ? 16 : 8} key={index}>
                <MyQueueMotion key={index} index={index}>
                    <Card
                        style={{
                            width: 300,
                            marginTop: 16,
                            borderRadius: 8,
                        }}
                        loading={isLoading}
                    />
                </MyQueueMotion>
            </Col>
        )
    }

    return (
        <Row
            gutter={16}
            style={{
                backgroundColor: "#f0f0f0",
                padding: "10px",
                paddingRight: "11px",
                paddingBottom: "20px",
                borderRadius: "10px",
                boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            {
                skeletonItems && skeletonItems.map((item, index) => {
                    return skeletonItem(index);
                })
            }
        </Row>
    )
}
