import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import NavComponent from "../common/nav";
import FooterComponent from "../common/footer";
import VideosComponent from "../common/videos";
import HeaderComponent from "../common/header";
import React from "react";

const VideosPage = () => {
    const userReducer = useSelector(reducer => reducer.userReducer);

    if (!userReducer.loggedIn)
        return <Redirect to="/login/videos" />

    return (
        <>
            <NavComponent/>
            <h2
                id={"health-area-title"}
                className="hero_text hero_text_align_center"
                style={{
                    marginTop: "40px",
                    marginBottom: "80px",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: 'bold',
                    fontOpticalSizing: "auto",
                    fontStyle: "normal",
                    textShadow: "0 0 5px #1c3564",
                }}
            >
                Videos
            </h2>
            <VideosComponent/>
            <FooterComponent/>
        </>
    )
}

export default VideosPage;
