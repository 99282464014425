import React, {useEffect, useState} from "react";
import {useQuery} from "react-query";
import service from "../../../config/service";
import {Button, Col, Input, Row, Space, Spin, Tabs, Tag} from "antd";
import {
    CalendarTwoTone,
    CheckSquareTwoTone,
    ClockCircleTwoTone,
    FilterOutlined,
    LeftOutlined,
    RightOutlined, SearchOutlined
} from "@ant-design/icons";
import {isMobile} from "react-device-detect";
import DesktopView from "./desktop";
import MobileView from "./mobile";
import Map from "./Map";
import useWindowDimensions, {isDev, isProduction} from "../../../utils/utils";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import OneSignal from "react-onesignal";


export const HomeComponent = () => {

    const {data, isError, isLoading} = useQuery('healthCenterService', async () => {
        const res = await service.get('/healthCenterService');
        return res.data;
    }, {refetchOnWindowFocus: false});

    const userReducer = useSelector(reducer => reducer.userReducer);

    const {height, width} = useWindowDimensions();

    const {loggedIn} = useSelector((state) => state.userReducer);
    const employee = localStorage.getItem('employee');

    const {t, i18n} = useTranslation();

    const [healthAreaData, setHealthAreaData] = useState([]);
    const [healthCenterData, setHealthCenterData] = useState([]);
    const [selectedHealthArea, setSelectedHealthArea] = useState('0');
    const [selectedHealthCenter, setSelectedHealthCenter] = useState([]);
    const [healthCenter, setHealthCenter] = useState(null);
    const [healthAreaMenuStyle, setHealthAreaMenuStyle] = useState('inline');

    const [isSearch, setIsSearch] = useState(false);
    const [searchValue, setSearchValue] = useState('');

    const [healthCenters, setHealthCenters] = useState([]);
    const [filteredHealthCenters, setFilteredHealthCenters] = useState([]);
    const [healthCentersService, setHealthCentersService] = useState([]);
    const [selectedHealthCenterService, setSelectedHealthCenterService] = useState(null);

    const {Search} = Input;

    const healthAreaLocalization = [
        {
            key: 'Ahmadi Health Area',
            en: "Ahmadi Health Area", ar: "منطقة الأحمدي الصحية"
        },
        {
            key: 'Capital Health Area',
            en: "Capital Health Area", ar: "منطقة العاصمة الصحية"
        },
        {
            key: 'Farwaniya Health Area',
            en: "Farwaniya Health Area", ar: "منطقة الفروانية الصحية"
        },
        {
            key: 'Hawalli Health Area',
            en: "Hawalli Health Area", ar: "منطقة حولي الصحية"
        },
        {
            key: 'Jahra Health Area',
            en: "Jahra Health Area", ar: "منطقة الجهراء الصحية"
        },
        {
            key: 'Mubarak AlKabeer Health Area',
            en: "Mubarak Al Kabeer Health Area", ar: "منطقة مبارك الكبير الصحية"
        }
    ];

    const serviceColumns = (title) => {
        return [{
            title: title, dataIndex: 'name', key: 'name',
        }];
    };

    useEffect(() => {

        if (isMobile) {
            setHealthAreaMenuStyle('horizontal');
        }
    }, []);

    useEffect(async () => {
        if (healthCenterData[selectedHealthArea] && healthCenter) {
            const healthCenterId = healthCenter.healthCenterInformation.healthCenterId;
            if (healthCenterId) {
                await service.put(`/healthCenterService/views/${healthCenterId}`)
            }
        }

    }, [selectedHealthCenter]);

    useEffect(async () => {

        if (isProduction()) {
            try {
                await OneSignal.init({appId: '4a1412c1-9b2c-4e72-885e-da9a32740a28'});
                await OneSignal.showSlidedownPrompt();

                OneSignal.addEventListener('received', (res) => console.log('received', res));
                OneSignal.addEventListener('opened', (res) => console.log('opened', res));
                OneSignal.addEventListener('ids', (res) => console.log('ids', res));
                OneSignal.configure();
            } catch (e) {
                return e;
            }
        }
    }, [isLoading]);


    useEffect(() => {

        const d = [];
        const dha = [];
        const dhc = [];

        // for each key obj ket in data convert to array and add to healthCenterData
        data && Object.keys(data).forEach((key, index) => {
            d.push({
                key: index,
                title: healthAreaLocalization.find((h) => h.key === key),
                label: healthAreaLocalization.find((h) => h.key === key),
                items: data[key]
            });
            dha.push({
                key: String(index), label: healthAreaLocalization.find((h) => h.key === key),
            });
            dhc[index] = '-1';
        });
        setHealthAreaData(dha);
        setHealthCenterData(d);
        setSelectedHealthCenter(dhc);
    }, [data]);


    useEffect(() => {

        if (healthCenterData.length > 0) {
            const healthCenters = healthCenterData.map((item) => {
                return item.items;
            });
            setHealthCenters(oldValue => oldValue.concat(...healthCenters));
            setFilteredHealthCenters(oldValue => oldValue.concat(...healthCenters));

            setHealthCentersService(
                healthCenters.map((item) => {
                    return item.map((item) => {
                        return item.primaryServices.concat(item.secondaryServices);
                    })
                }).filter((item, index, array) => {
                    return array.indexOf(item) === index;
                })
            )
        }

    }, [healthCenterData]);

    useEffect(() => {

        if (searchValue.length > 0) {
            if (healthCenters.length > 0) {
                setFilteredHealthCenters(
                    healthCenters.filter((item) => {
                        return item.centerName.nameEng.toLowerCase().includes(searchValue.toLowerCase()) ||
                            item.centerName.nameArb.toLowerCase().includes(searchValue.toLowerCase())
                    })
                )
            }
        } else {
            setFilteredHealthCenters(healthCenters);
        }
    }, [searchValue]);

    // if (isLoading) {
    //
    //     return (<div style={{
    //         height: height * 0.50,
    //         minHeight: height * 0.50,
    //         display: "flex",
    //         flexDirection: "column",
    //         alignItems: "center",
    //         justifyContent: "center"
    //     }}
    //     >
    //         <Spin
    //             spinning={true}
    //             size="default"
    //         ></Spin>
    //         <div className="text-center">{t('pleaseWait')}</div>
    //     </div>);
    // }

    const onSelectHealthArea = (e) => {
        setSelectedHealthArea(e.key);
        setSelectedHealthCenter((oldArray) => {
            const newArray = [...oldArray];
            newArray[selectedHealthArea] = '-1';
            return newArray;
        });
        setHealthCenter(null);
    };

    const onSelectHealthCenter = (e) => {
        if (isSearch) {
            setHealthCenter(filteredHealthCenters[e.key]);
        } else {
            setSelectedHealthCenter((oldArray) => {
                const newArray = [...oldArray];
                newArray[selectedHealthArea] = e.key;
                return newArray;
            });
            setHealthCenter(healthCenterData[selectedHealthArea].items[e.key]);
        }
    };

    const healthCenterHoursFormat = (workingHours) => {

        if (workingHours.length === 0) return i18n.language === 'ar' ? 'لا يوجد ساعات عمل' : 'No working hours';

        if (Number(workingHours) === 7) {
            if (i18n.language === 'en' || 'en-US') return '7:00 am to 2:00 pm';
            if (i18n.language === 'ar') return '7:00 صباحاً إلى 2:00 مساءً';
        }

        if (Number(workingHours) === Number(14)) {
            if (i18n.language === 'en' || 'en-US') return '07:00 am to 9:00 pm';
            if (i18n.language === 'ar') return '07:00 صباحاً إلى 9:00 مساءً';
        }

        if (Number(workingHours) === 17) {
            if (i18n.language === 'en' || 'en-US') return '07:00 am to 12:00 am';
            if (i18n.language === 'ar') return '07:00 صباحاً إلى 12:00 صباحاً';
        }

        if (Number(workingHours) === 24) return i18n.language === 'ar' ? '24 ساعة' : '24 hours';
    }

    const healthCenterDaysFormat = (weekendDuty) => {

        if (weekendDuty === "yes") {
            return i18n.language === 'ar' ? 'طوال ايام الاسبوع' : 'All days of the week';
        } else {
            return i18n.language === 'ar' ? 'الأحد إلى الخميس' : 'Sunday to Thursday';
        }
    }

    // merge the two arrays
    const healthCenterServices = (healthCenterPrimaryService, healthCenterSecoundryService) => {
        return healthCenterPrimaryService.concat(healthCenterSecoundryService);
    }

    const healthCenterLocation = (healthCenter) => {
        return {
            lat: healthCenter.healthCenterInformation.coordinates.latitude,
            lng: healthCenter.healthCenterInformation.coordinates.longitude,
            address: healthCenter.centerName.nameEng,
        }
    }

    const onSearch = (value) => {

        if (value === '') {
            setIsSearch(false);
            setSearchValue('');
        } else {
            setIsSearch(true);
            setSearchValue(value);
        }
    };

    const checkCatchment = (healthCenter) => {
        let hasCatchment = {
            weekDay: {
                isAvailable: false,
                catchment: null,
            },
            weekEnd: {
                isAvailable: false,
                catchment: null,
            },
        };

        if (healthCenter.healthCenterInformation.catchment.find((catchment) => catchment.catchmentType === 'WEEK_DAY' && catchment.details !== '')) {
            hasCatchment.weekDay.isAvailable = true;
            hasCatchment.weekDay.catchment = healthCenter.healthCenterInformation.catchment.find((catchment) => catchment.catchmentType === 'WEEK_DAY');
        }

        if (healthCenter.healthCenterInformation.catchment.find((catchment) => catchment.catchmentType === 'WEEK_END' && catchment.details !== '')) {
            hasCatchment.weekEnd.isAvailable = true;
            hasCatchment.weekEnd.catchment = healthCenter.healthCenterInformation.catchment.find((catchment) => catchment.catchmentType === 'WEEK_END');
        }

        return hasCatchment;
    }

    const catchmentTabs = (healthCenter) => {
        const hasCatchment = checkCatchment(healthCenter);

        if (hasCatchment.weekDay.isAvailable && hasCatchment.weekEnd.isAvailable) {
            return (
                <Tabs
                    type="card"
                    centered
                >
                    <Tabs.TabPane tab="Week Days" key="item-1">
                        {hasCatchment.weekDay.isAvailable ? hasCatchment.weekDay.catchment.details : 'Not Available'}
                    </Tabs.TabPane>
                    <Tabs.TabPane tab="Week End & Holidays" key="item-2">
                        {hasCatchment.weekEnd.isAvailable ? hasCatchment.weekEnd.catchment.details : 'Not Available'}
                    </Tabs.TabPane>
                </Tabs>
            );
        } else if (hasCatchment.weekDay.isAvailable) {
            return hasCatchment.weekDay.catchment.details;
        } else if (hasCatchment.weekEnd.isAvailable) {
            return hasCatchment.weekEnd.catchment.details;
        } else {
            return i18n.language === 'ar' ? 'لا يوجد معلومات' : 'No information';
        }
    }


    return (
        <div
            id={'main-home-container'}
            style={{
                minHeight: height * 0.65,
            }}
            dir={i18n.dir()}
        >
            <DesktopView
                healthCenterData={healthCenterData}
                selectedHealthArea={selectedHealthArea}
                selectedHealthCenter={selectedHealthCenter}
                onSelectHealthArea={onSelectHealthArea}
                onSelectHealthCenter={onSelectHealthCenter}
                healthAreaMenuStyle={healthAreaMenuStyle}
                isSearch={isSearch}
                setIsSearch={setIsSearch}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                filteredHealthCenters={filteredHealthCenters}
            />
            {/*{isMobile ? <MobileView*/}
            {/*    healthCenterData={healthCenterData}*/}
            {/*    selectedHealthArea={selectedHealthArea}*/}
            {/*    selectedHealthCenter={selectedHealthCenter}*/}
            {/*    onSelectHealthArea={onSelectHealthArea}*/}
            {/*    onSelectHealthCenter={onSelectHealthCenter}*/}
            {/*    healthAreaMenuStyle={healthAreaMenuStyle}*/}
            {/*    healthCenter={healthCenter}*/}
            {/*    isSearch={isSearch}*/}
            {/*    setIsSearch={setIsSearch}*/}
            {/*    searchValue={searchValue}*/}
            {/*    setSearchValue={setSearchValue}*/}
            {/*    filteredHealthCenters={filteredHealthCenters}*/}
            {/*/> : <DesktopView*/}
            {/*    healthCenterData={healthCenterData}*/}
            {/*    selectedHealthArea={selectedHealthArea}*/}
            {/*    selectedHealthCenter={selectedHealthCenter}*/}
            {/*    onSelectHealthArea={onSelectHealthArea}*/}
            {/*    onSelectHealthCenter={onSelectHealthCenter}*/}
            {/*    healthAreaMenuStyle={healthAreaMenuStyle}*/}
            {/*    isSearch={isSearch}*/}
            {/*    setIsSearch={setIsSearch}*/}
            {/*    searchValue={searchValue}*/}
            {/*    setSearchValue={setSearchValue}*/}
            {/*    filteredHealthCenters={filteredHealthCenters}*/}
            {/*/>}*/}
            {healthCenterData[selectedHealthArea] && healthCenter && <Row>
                <Col
                    span={24}
                    style={{
                        width: '600px', textAlign: "center", margin: "35px 0", borderRadius: "10px", padding: "20px",
                    }}
                >

                    {isMobile ? <Row>
                            <Col span={4}>
                                <Button
                                    shape="circle"
                                    icon={i18n.dir() === 'rtl' ? <RightOutlined/> : <LeftOutlined/>}
                                    size='middle'
                                    onClick={() => setHealthCenter(null)}
                                />
                            </Col>
                            <Col span={20}>
                                <h5>{i18n.language === 'ar' ? healthCenter.centerName.nameArb : healthCenter.centerName.nameEng}</h5>
                            </Col>
                        </Row> :
                        <h4>{i18n.language === 'ar' ? healthCenter.centerName.nameArb : healthCenter.centerName.nameEng}</h4>}
                    <Row style={{marginTop: "40px"}}>
                        <Col span={12}>
                            <div style={{
                                display: 'flex',
                                flexDirection: i18n.dir() === 'rtl' ? 'row-reverse' : 'row',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <CalendarTwoTone style={{marginRight: "10px"}}/>
                                <span>{t('healthCenterInformation.workingDays')}</span>
                            </div>
                            <p>{healthCenterDaysFormat(healthCenter.weekendDuty)}</p>
                        </Col>
                        <Col span={12}>
                            <div style={{
                                display: 'flex',
                                flexDirection: i18n.dir() === 'rtl' ? 'row-reverse' : 'row',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}>
                                <ClockCircleTwoTone style={{marginRight: "10px"}}/>
                                <span>{t('healthCenterInformation.workingHours')}</span>
                            </div>
                            <p>{healthCenterHoursFormat(healthCenter.workingHours)}</p>
                        </Col>
                    </Row>
                    <Row style={{marginTop: "40px"}}>
                        <Col span={24}>
                            <div style={{
                                display: 'flex',
                                flexDirection: i18n.dir() === 'rtl' ? 'row-reverse' : 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                                marginBottom: "20px"
                            }}>
                                <CheckSquareTwoTone style={{marginRight: "10px"}}/>
                                <span>{t('healthCenterInformation.catchment')}</span>
                            </div>
                            {catchmentTabs(healthCenter)}
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginTop: "30px",
                        }}
                    >
                        <Col
                            span={24}
                            style={{
                                display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
                            }}
                        >
                            <h6
                                style={{fontWeight: "bold"}}
                            >
                                {t('home.healthCenterInformation.service.title')}
                            </h6>
                            <Space style={{marginTop: "50px"}} size={[0, 8]} wrap>
                                {healthCenterServices(healthCenter.primaryServices, healthCenter.secondaryServices).map((item, index) => {
                                    return (<Tag color="blue"
                                                 key={index}>{i18n.language === 'ar' ? item.name.nameArb : item.name.nameEng}</Tag>)
                                })}
                            </Space>
                        </Col>
                    </Row>
                    <Row
                        style={{
                            marginTop: "50px",
                        }}
                    >
                        <Col
                            span={24}
                            style={{
                                display: 'flex',
                                flexDirection: i18n.dir() === 'rtl' ? 'column-reverse' : 'column',
                                alignItems: 'center',
                                justifyContent: 'center'
                            }}
                        >
                            <Map location={healthCenterLocation(healthCenter)}/>
                        </Col>
                    </Row>
                </Col>
            </Row>}
        </div>
    );
}

const styles = {
    serviceItem: {
        alignSelf: 'flex-start',
    }
}

export default HomeComponent;
