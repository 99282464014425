const defaultState = {
    loggedIn: false, /// default is false
    user: {},
    logginErrorMessage: undefined,
    loading: false,
    passwordSet: false,
    forgetPasswordMessage: undefined,
    selectedHealthArea: undefined,

    // reset password status [init, pending, error, completed]
    resetPasswordStatus: 0,
    resetPasswordResponse: {},
    resetPasswordSuccess: undefined,
    resetPasswordErrorMessage: undefined
}

const userReducer = (state = defaultState, action) => {
    switch (action.type) {
        case "SET_USER":
            return {
                ...state,
                loggedIn: true,
                user: {...action.payload},
                logginErrorMessage: undefined,
                loading: false
            }

        case "LOG_OUT":
            localStorage.clear();
            return {
                ...state,
                loggedIn: false,
                user: {},
                logginErrorMessage: undefined,
                loading: false
            }
        case "ERROR_LOGIN":

            return {
                ...state,
                logginErrorMessage: action.payload,
                loggedIn: false,
                loading: false

            }
        case "USER_IS_LOADING":
            return {
                ...state,
                loading: true
            }

        case "FORGET_PASSWORD":
            return {
                ...state,
                loading: false,
                forgetPasswordMessage: action.payload,
                resetPasswordStatus: 1
            }

        case "RESET_PASSWORD_SUCCESS":
            return {
                ...state,
                loading: false,
                passwordSet: true,
                resetPasswordResponse: action.payload,
                resetPasswordStatus: 3,
                resetPasswordSuccess: true,
                resetPasswordErrorMessage: undefined
            }

        case "RESET_PASSWORD_FAILED":
            return {
                ...state,
                loading: false,
                passwordSet: false,
                resetPasswordResponse: action.payload,
                resetPasswordStatus: 2,
                resetPasswordSuccess: false,
                resetPasswordErrorMessage: action.payload.response
            }

        case "RESET_PASSWORD_UPDATE_STATUE":
            return {
                ...state,
                resetPasswordStatus: action.payload
            }

        case "RESET_PASSWORD_CLEAR":
            return {
                ...state,
                loading: false,
                passwordSet: false,
                resetPasswordSuccess: undefined,
                resetPasswordErrorMessage: undefined
            }
        case "SET_SELECTED_HEALTH_AREA":
            return {
                ...state,
                selectedHealthArea: action.payload
            }
        default:
            return state
    }
}

export default userReducer;
