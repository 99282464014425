import React, {useEffect} from "react";
import {logoutUser} from "../../redux/actions/userActions";
import {Link, useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {BellOutlined, GlobalOutlined, SettingFilled} from "@ant-design/icons";
import {isLocalIT} from "../../utils/utils";
import {useTranslation} from "react-i18next";
import service from "../../config/service";
import moment from "moment";

const NavComponent = () => {

    const dispatch = useDispatch();
    const userReducer = useSelector((reducer) => reducer.userReducer);
    const [notificationsMessage, setNotificationsMessage] = React.useState([]);

    const {t, i18n} = useTranslation();

    const [currentLang, setCurrentLang] = React.useState(i18n.language);

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setCurrentLang(i18n.language);
    }

    useEffect(async () => {

        if (userReducer.loggedIn) {
            await service('/notification/')
                .then((response) => {
                    setNotificationsMessage(response.data);
                });
        }

    }, [userReducer.loggedIn]);

    const langButtons = () => {

        if (userReducer.loggedIn) {
            return (
                <>
                    {
                        currentLang === 'en' ?
                            <a
                                className="dropdown-item"
                                onClick={() => changeLanguage('ar')}
                            >
                                <p>اللغة العربية</p>
                            </a> :
                            <a
                                className="dropdown-item"
                                onClick={() => changeLanguage('en')}
                            >
                                <p>English</p>
                            </a>
                    }
                </>
            );
        }

        return (
            <li
                className="nav-item"
            >
                {
                    currentLang === 'en' ?
                        <a
                            className="nav-link"
                            onClick={() => changeLanguage('ar')}
                        >
                            <p>اللغة العربية</p>
                        </a> :
                        <a
                            className="nav-link"
                            onClick={() => changeLanguage('en')}
                        >
                            <p>English</p>
                        </a>
                }
            </li>
        );
    }

    const notificationButton = () => {
        return (
            <li className="nav-item dropdown dropdown_header_custom">
                <a
                    className="nav-link dropdown-toggle dropdown_header_main"
                    href="#"
                    id="navbarDarkDropdownMenuNotification"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    <BellOutlined/>
                </a>
                <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDarkDropdownMenuNotification"
                >
                    {
                        notificationsMessage.length > 0 &&
                        notificationsMessage.map((item, index) => {
                            return renderNotificationItem(item);
                        })
                    }
                </div>
            </li>
        )
    }

    const renderNotificationItem = (item) => {
        return (
            <div key={item.id} className="dropdown-item">
                <span><b>{item.title}</b></span><br/>
                <span>{item.body}</span><br/>
                <span
                    style={{fontSize: '12px', color: '#999999'}}
                >{moment(item.createdDate).format('DD MMMM yyyy')}</span><br/>
            </div>
        )
    }

    return (
        <section className="dark_bg">
            <nav className="navbar navbar-dark navbar-expand-md navigation-clean-button myNav dark_bg" dir={i18n.dir()}>
                <div className="container">
                    <a className="nav-brand" href="/">
                        <img width="100" style={{borderRadius: '50%'}} src="./assets/img/cdphc-logo.png"
                             alt="CDPHC Logo"/>
                    </a>
                    <button
                        data-toggle="collapse"
                        className="navbar-toggler"
                        data-target="#navcol-1"
                    >
                        {/*<span className="visually-hidden">Toggle navigation</span>*/}
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navcol-1">
                        <ul className="navbar-nav me-auto">

                            <li className="nav-item">
                                <Link className="nav-link" to="/whoweare">
                                    {t('nav.menu.whoWeAre')}
                                </Link>
                            </li>
                            {/*<li className="nav-item">*/}
                            {/*    <Link className="nav-link" to="/stats">*/}
                            {/*        {t('views.stats.title')}*/}
                            {/*    </Link>*/}
                            {/*</li>*/}

                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button"
                                   data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    {t('nav.menu.documents.title')}
                                </a>
                                <div className="dropdown-menu" aria-labelledby="navbarDropdown">
                                    <Link className="dropdown-item" to="/protocols">
                                        {t('nav.menu.documents.subMenu.protocols')}
                                    </Link>
                                    <Link className="dropdown-item" to="/policies">
                                        {t('nav.menu.documents.subMenu.policies')}
                                    </Link>
                                    <Link className="dropdown-item" to="/guidelines">
                                        {t('nav.menu.documents.subMenu.guidelines')}
                                    </Link>
                                    <Link className="dropdown-item" to="/information">
                                        {t('nav.menu.documents.subMenu.information')}
                                    </Link>
                                    {
                                        !isLocalIT() &&
                                        <Link className="dropdown-item" to="/circulars">
                                            {t('nav.menu.documents.subMenu.circulars')}
                                        </Link>
                                    }
                                    <Link className="dropdown-item" to="/booklets">
                                        {t('nav.menu.documents.subMenu.booklets')}
                                    </Link>
                                </div>
                            </li>
                            {
                                !isLocalIT() &&
                                <li className="nav-item">
                                    <Link className="nav-link" to="/videos">
                                        {t('nav.menu.videos')}
                                    </Link>
                                </li>
                            }
                            {
                                !isLocalIT() &&
                                <li className="nav-item">
                                    <Link className="nav-link" to="/events">
                                        {t('nav.menu.events')}
                                    </Link>
                                </li>
                            }
                            {
                                !isLocalIT() &&
                                <li className="nav-item">
                                    <Link className="nav-link" to="/contact">
                                        <p>
                                            {t('nav.menu.contactUs')}
                                        </p>
                                    </Link>
                                </li>
                            }
                            {userReducer.loggedIn === false && !isLocalIT() ? (
                                <div
                                    className="nav-item"
                                    style={{display: "flex"}}
                                >
                                    <li className="nav-item">
                                        <a className="nav-link" href="https://register.cdphc.com" target="_blank">
                                            {t('nav.menu.register')}
                                        </a>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/login">
                                            {t('nav.menu.login')}
                                        </Link>
                                    </li>
                                    {langButtons()}
                                </div>
                            ) : !isLocalIT() && (
                                <>
                                    <li className="nav-item dropdown dropdown_header_custom">
                                        <a
                                            className="nav-link dropdown-toggle dropdown_header_main"
                                            href="#"
                                            id="navbarDarkDropdownMenuLink"
                                            role="button"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <SettingFilled/>
                                        </a>

                                        <div
                                            className="dropdown-menu"
                                            aria-labelledby="navbarDarkDropdownMenuLink"
                                        >
                                            <Link className="dropdown-item dropdown_links" to="profile">
                                                {t('nav.menu.profile')}
                                            </Link>
                                            <Link
                                                className="dropdown-item dropdown_links"
                                                to="/updatePassword"
                                            >
                                                {t('nav.menu.updatePassword')}
                                            </Link>
                                            <a
                                                type="text"
                                                className="dropdown-item dropdown_links"
                                                onClick={() => dispatch(logoutUser())}
                                            >
                                                ( {t('nav.menu.logout')} )
                                            </a>
                                            {langButtons()}
                                        </div>
                                    </li>
                                </>
                            )}
                            {/*{*/}
                            {/*    userReducer.loggedIn && notificationButton()*/}
                            {/*}*/}
                        </ul>
                    </div>
                </div>
            </nav>
        </section>
    );
};

export default NavComponent;
