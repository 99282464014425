import {useQuery} from "react-query";
import service from "../../config/service";
import {Alert, Spin} from "antd";
import Documents from "./Documents";
import React from "react";
import {useTranslation} from "react-i18next";
import useWindowDimensions from "../../utils/utils";
import {Redirect} from "react-router";
import {useDispatch} from "react-redux";
import {logoutUser} from "../../redux/actions/userActions";

const ProtocolsComponent = () => {

    const {height, width} = useWindowDimensions();
    const {t} = useTranslation();
    const dispatch = useDispatch();

    const {data, isError, isLoading} = useQuery(
        'protocols',
        async () => {
            const res = await service.get('/protocols/enable');
            return res.data;
        },
        {refetchOnWindowFocus: false}
    );

    if (isLoading) {

        return (
            <div style={{
                height: height * 0.65,
                minHeight: height * 0.65,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center"
            }}
            >
                <Spin
                    spinning={true}
                    size="default"
                ></Spin>
                <div className="text-center">{t('pleaseWait')}</div>
            </div>
        );
    }

    if (isError) {
        dispatch(logoutUser());
        return <Redirect to="/login"/>;
    }

    return (<Documents data={data} title='Protocols' for='protocols'/>);

}

export default ProtocolsComponent;
