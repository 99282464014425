import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import useWindowDimensions from "../../../utils/utils";
import {useQuery} from "react-query";
import service from "../../../config/service";
import {Redirect, useParams} from "react-router";
import {Skeleton} from "antd";
import i18n from "i18next";
import {useHistory} from "react-router-dom";

export default () => {

    const {t} = useTranslation();
    const {width, height} = useWindowDimensions();

    const [mainData, setMainData] = useState([]);
    const [d, setD] = useState([])
    const [dha, setDha] = useState([])
    const [healthCenters, setHealthCenters] = useState([]);
    const [filteredHealthCenters, setFilteredHealthCenters] = useState([]);
    const navigation = useHistory();

    const [healthArea, setHealthArea] = useState(null);

    const {data, isError, isLoading} = useQuery('healthCenterService', async () => {
        const res = await service.get('/healthCenterService');
        return res.data;
    }, {refetchOnWindowFocus: false});

    const healthAreaLocalization = [{
        key: 'Ahmadi Health Area', en: "Ahmadi Health Area", ar: "منطقة الأحمدي الصحية"
    }, {
        key: 'Capital Health Area', en: "Capital Health Area", ar: "منطقة العاصمة الصحية"
    }, {
        key: 'Farwaniya Health Area', en: "Farwaniya Health Area", ar: "منطقة الفروانية الصحية"
    }, {
        key: 'Hawalli Health Area', en: "Hawalli Health Area", ar: "منطقة حولي الصحية"
    }, {
        key: 'Jahra Health Area', en: "Jahra Health Area", ar: "منطقة الجهراء الصحية"
    }, {
        key: 'Mubarak AlKabeer Health Area', en: "Mubarak Al Kabeer Health Area", ar: "منطقة مبارك الكبير الصحية"
    }]

    useEffect(() => {

        if (data) {
            setMainData(data);
        }

        const getData = async () => {
            new Promise((resolve, reject) => {
                try {
                    const d = [];
                    const dha = [];
                    const dhc = [];

                    // for each key obj ket in data convert to array and add to healthCenterData
                    data && Object.keys(data).forEach((key, index) => {
                        d.push({
                            key: index,
                            title: healthAreaLocalization.find((h) => h.key === key),
                            label: healthAreaLocalization.find((h) => h.key === key),
                            items: data[key]
                        });
                        dha.push({
                            key: String(index), label: healthAreaLocalization.find((h) => h.key === key),
                        })
                        dhc[index] = '-1';
                    });
                    resolve({d, dha, dhc});
                } catch (e) {
                    reject(e);
                }
            }).then(({d, dha, dhc}) => {
                setD(d);
                setDha(dha);
            });
        }

        getData();

        return () => {
            getData();
        }
    }, [data]);

    useEffect(() => {
        if (healthArea) {
            console.log('useEffect healthArea: ', healthArea)
            let healthAreaIndex = -1;
            for (let i = 0; i < dha.length; i++) {
                if (dha[i].label.en.toLowerCase() === healthArea || dha[i].ar === healthArea) {
                    healthAreaIndex = i;
                    break;
                }
            }

            console.log('healthAreaIndex: ', healthAreaIndex)
            // if healthArea is not in the list redirect to 404
            if (healthAreaIndex === -1) {
                return;
            }

            if (d[healthAreaIndex]) {
                setHealthCenters(d[healthAreaIndex].items);
                setFilteredHealthCenters(d[healthAreaIndex].items);
            }
        }
    }, [healthArea]);

    const capitalizeTitle = (title) => {
        // capitalize a first letter of each word
        return title.split(' ').map((word) => {
            return word.charAt(0).toUpperCase() + word.slice(1);
        }).join('\n');
    }

    const healthAreaAndHealthCenterDroplist = () => {
        return (
            <div>
                <div>
                    <select
                        onChange={(e) => {
                            console.log('e.target.value: ', e.target.value)
                            setHealthArea(e.target.value.toLowerCase());
                        }}
                        style={{
                            backgroundColor: "white",
                            color: "black",
                            padding: "10px",
                            width: "100%",
                            borderRadius: "5px",
                            marginBottom: "20px",
                            border: "none",
                        }}
                    >
                        <option value={""}>{t("hci.selectHealthArea")}</option>
                        {healthAreaLocalization && healthAreaLocalization.map((item, index) => {
                            return (
                                <option key={index} value={item.key}>
                                    {i18n.language === "en-US" ? item.en : item.ar}
                                </option>
                            );
                        })}
                    </select>
                </div>
                {/* show a health center for selected health Area in a drop list if still the health area not selected show empty droplet with a message */}
                {healthCenters && healthCenters.length > 0 && (
                    <div>
                        <select
                            onChange={(e) => {
                                const value = e.target.value;
                                navigation.push('/health-center/' + value, {
                                    f: 'hci'
                                })
                            }}
                            style={{
                                backgroundColor: "white",
                                color: "black",
                                padding: "10px",
                                width: "100%",
                                borderRadius: "5px",
                                marginBottom: "20px",
                                border: "none",
                            }}
                        >
                            <option value={""}>{t("hci.selectHealthCenter")}</option>
                            {filteredHealthCenters && filteredHealthCenters.map((item, index) => {
                                return (
                                    <option key={index} value={item.centerName.nameEng}>
                                        {i18n.language === "en-US" ? item.centerName.nameEng : item.centerName.nameArb}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                )}
            </div>
        );
    }

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                height: "100vh",
                backgroundColor: "#1c3564",
            }}
        >
            <img width="180" style={{borderRadius: '50%'}} src="./assets/img/cdphc-logo.png"
                 alt="CDPHC Logo"/>
            <h1
                id={"hci-title"}
                className="hero_text hero_text_align_center"
                style={{
                    marginBottom: "80px",
                    textAlign: "left",
                    fontFamily: "Montserrat, sans-serif",
                    fontWeight: 800,
                    fontSize: "2em",
                    fontOpticalSizing: "auto",
                    fontStyle: "normal",
                    color: "white",
                    marginTop: "50px",
                }}
            >
                {t("hci.title")}
            </h1>
            {
                isLoading ? <Skeleton style={{marginTop: 20, width: "40%"}} active/>
                    : healthAreaAndHealthCenterDroplist()
            }
        </div>
    )
}
