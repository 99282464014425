import React from "react";
import { useQuery } from "react-query";
import service from "../../config/service";
import { Col, Row, Spin, Alert } from "antd";
import { useState } from "react";
import EmployeeEnroll from "./EmployeeEnroll";

const EmployeeSearh = (props) => {
  const [activeSearch, setActiveSearch] = props.setActiveSearch;
  const employeeId = props.employeeId;
  const eventId = props.eventId;
  const [employeeName, setEmployeeName] = useState(null);

  const { data, isLoading, isError } = useQuery(
    ["employee", employeeId],
    async () => {
      const res = await service.get("/employees/by/fileNumber/" + employeeId);
      setEmployeeName(res.data.response.personalInformation.name.nameEng);
      return res.data;
    },
    { refetchOnWindowFocus: false }
  );

  if (isLoading) {
    return (
      <Spin spinning={true} size="default">
        <div className="text-center">Please wait while loading...</div>
      </Spin>
    );
  }

  if (isError)
    return (
      <div className="text-center">
        <Alert
          message="Error loading the page, please refresh the page or try again later."
          type="error"
        ></Alert>
      </div>
    );

  return (
    <>
      {activeSearch == true ? (
        <div>
          <Row justify="space-around" style={{ margin: 25 }}>
            <Col span={8}>{employeeId}</Col>
            <Col span={8}>{employeeName}</Col>
            <Col span={8}>
              <EmployeeEnroll
                callBack={props.callBack}
                employeeId={employeeId}
                eventId={eventId}
                setActiveSearch={setActiveSearch}
              ></EmployeeEnroll>
            </Col>
          </Row>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default EmployeeSearh;
