import Forget from "../auth/forget";
import NavComponent from "../common/nav";
import FooterComponent from "../common/footer";
import HeaderComponent from "../common/header";

const ForgetRequestPage = () => {
    return (
        <>
            <NavComponent />
            <HeaderComponent />
            <Forget />
            <FooterComponent />
        </>
    );
}

export default ForgetRequestPage;