import React, {useEffect, useState} from "react";
import {Alert, Button, Form, Input} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {
    forgetPassword,
    resetPassword,
    resetPasswordStatueUpdate
} from "../../redux/actions/userActions";
import {useTranslation} from "react-i18next";
import useWindowDimensions from "../../utils/utils";

const Forget = ({history}) => {

    const {height, width} = useWindowDimensions();
    const userReducer = useSelector((state) => state.userReducer);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showWarnAlert, setShowWarnAlert] = useState(false);
    const [counter, setCounter] = useState(5);
    const [submitedEmail, setSubmitedEmail] = useState('');
    const [warnAlertMessage, setWarnAlertMessage] = useState('')

    const dispatch = useDispatch();

    const {t, i18n} = useTranslation();

    useEffect(() => {

        if (userReducer.resetPasswordStatus === 2) {
            setShowWarnAlert(true)
            setWarnAlertMessage(userReducer.resetPasswordResponse);
            setTimeout(() => {
                dispatch(resetPasswordStatueUpdate(1));
            }, 2500)
        }

        if (userReducer.resetPasswordStatus === 3) {
            setShowSuccessAlert(true);
        }

    }, [userReducer.resetPasswordStatus])

    const requestForgetPassword = (values) => {

        if (userReducer.loggedIn) {
            alert("You're already logged in");
        } else {
            values["lang"] = "en";
            dispatch(forgetPassword(values));
            setSubmitedEmail(values.email);
        }
    };

    const updatePassword = (values) => {
        dispatch(resetPassword(values));
    }

    const alertMessage = () => {
        return t("views.forget.passwordUpdated", {counter});
    }

    const warnAlertMessageGenerator = () => {
        return `${warnAlertMessage}`;
    }

    const alertAction = () => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
        if (showSuccessAlert) {
            if (counter === 0) {
                setShowWarnAlert(false);
                window.location.href = "./login";
            }
        } else if (showWarnAlert) {
            if (counter === 0) {
                setCounter(5)
                setWarnAlertMessage('')
            }
        }
    }

    return (
        <div
            className="col-md-4 offset-md-4"
            style={{
                direction: i18n.dir() === "rtl" ? "rtl" : "ltr",
                textAlign: i18n.dir() === "rtl" ? "right" : "left",
                minHeight: (height * 0.65),
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-around"
            }}
        >

            {!userReducer.forgetPasswordMessage ? (
                <div
                    style={{marginTop: "50px", display: 'flex', justifyContent: 'center', flexDirection: 'column'}}
                >
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'column',
                            textAlign: 'center'
                        }}
                    >
                        <h3>
                            {t("views.forget.title")}
                        </h3>
                        <h6>
                            {t("views.forget.subtitle")}
                        </h6>
                    </div>
                    <Form
                        layout="vertical"
                        name="login"
                        initialValues={{remember: true}}
                        onFinish={requestForgetPassword}
                        style={{marginTop: "30px"}}
                    >
                        <Form.Item
                            label={t("views.forget.email")}
                            name="email"
                            rules={[{required: true, message: t("views.forget.errors.email")}]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={userReducer.loading}
                            >
                                {t("views.forget.submit")}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            ) : (
                <div
                    style={{marginTop: "50px", display: 'flex', justifyContent: 'center', flexDirection: 'column'}}
                >
                    <Form
                        layout="vertical"
                        width="40%"
                        name="login"
                        initialValues={{remember: true}}
                        onFinish={updatePassword}
                    >
                        <Form.Item
                            label={t("views.forget.email")}
                            name="email"
                            rules={[{required: true, message: t("views.forget.errors.email")}]}
                            initialValue={submitedEmail}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label={t("views.forget.password")}
                            name="password"
                            type="password"
                            rules={[{required: true, message: t("views.forget.errors.password")}]}
                        >
                            <Input type="password"/>
                        </Form.Item>
                        <Form.Item
                            label={t("views.forget.otp")}
                            name="token"
                            rules={[{required: true, message: t("views.forget.errors.otp")}]}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={userReducer.loading}
                            >
                                {t("views.forget.submit")}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            )
            }
            {
                showSuccessAlert ? (
                    <Alert
                        message="Success"
                        description={alertMessage()}
                        type="success"
                        showIcon
                        style={{marginTop: "20px", marginBottom: "20px"}}
                        action={alertAction}
                    />
                ) : null
            }
            {
                showWarnAlert && (
                    <Alert
                        message="Warn"
                        description={warnAlertMessageGenerator()}
                        type="warning"
                        showIcon
                        style={{marginTop: "20px", marginBottom: "20px"}}
                    />
                )
            }
        </div>
    );
};

export default Forget;
