import React, {useEffect} from "react";
import {Form, Input, Button, Alert} from "antd";
import {useSelector, useDispatch} from "react-redux";
import {
    resetPassword,
    updateCurrentPassword,
    resetPasswordClear,
} from "../../redux/actions/userActions";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

const SetPassword = () => {

    const userReducer = useSelector((state) => state.userReducer);
    const dispatch = useDispatch();
    const {token} = useParams();

    const {t, i18n} = useTranslation();

    useEffect(() => {
        dispatch(resetPasswordClear());
    }, []);

    const onFinish = (values) => {
        if (token) {
            values["token"] = token;
            dispatch(resetPassword(values));
        } else {
            dispatch(updateCurrentPassword(values));
        }
    };

    const showSuccessOrErrorMessage = () => {
        if (userReducer.resetPasswordSuccess) {
            return <Alert message={t('views.updatePassword.success')} type="success"/>;
        } else if (userReducer.resetPasswordSuccess === false) {
            return (
                <Alert message={userReducer.resetPasswordErrorMessage} type="error"/>
            );
        } else {
            return null;
        }
    };

    let display = t('views.updatePassword.email');
    let field = "email";

    if (userReducer.loggedIn) {
        display = t('views.updatePassword.currentPassword');
        field = "password";
    }

    return (
        <>
            <div className="page_title">
                <h1>
                    {t('views.updatePassword.title')}
                </h1>
            </div>
            <div
                className="col-md-4 offset-md-4"
                style={{marginTop: "50px", textAlign: i18n.dir() === "ltr" ? "left" : "right", direction: i18n.dir()}}
            >
                <h6>
                    {t('views.updatePassword.subtitle', {display: display})}
                </h6>

                <Form
                    layout="vertical"
                    width="40%"
                    name="login"
                    initialValues={{remember: true}}
                    onFinish={onFinish}
                    style={{marginTop: "30px"}}
                >
                    <Form.Item
                        label={display}
                        name="currentPassword"
                        rules={[{required: true, message: t('views.updatePassword.errors.currentPassword')}]}
                    >
                        {userReducer.loggedIn ? <Input.Password/> : <Input/>}
                    </Form.Item>

                    <Form.Item
                        label={t('views.updatePassword.newPassword')}
                        name="password"
                        rules={[{required: true, message: t('views.updatePassword.errors.newPassword')}]}
                    >
                        <Input.Password/>
                    </Form.Item>

                    <Form.Item
                        label={t('views.updatePassword.confirmPassword')}
                        name="password2"
                        dependencies={["password"]}
                        rules={[
                            {required: true, message: t('views.updatePassword.errors.confirmPassword')},
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue("password") === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(
                                        new Error(
                                            t('views.updatePassword.errors.passwordMismatch')
                                        )
                                    );
                                },
                            }),
                        ]}
                    >
                        <Input.Password/>
                    </Form.Item>

                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={userReducer.loading}
                        >
                            {t('views.updatePassword.submit')}
                        </Button>
                    </Form.Item>
                </Form>
                {showSuccessOrErrorMessage()}
                <br/>
            </div>
        </>
    );
};

export default SetPassword;
