import service, {setClientToken} from '../../config/service';
import jwt from 'jsonwebtoken';

// Action creators
const setUserAction = (payload) => ({ type: "SET_USER", payload })
const errorLoginAction = (payload) => ({ type: "ERROR_LOGIN", payload })
export const logUserOutAction = () => ({ type: "LOG_OUT" })
export const userIsLoadingAction = () => ({ type: "USER_IS_LOADING" })
export const forgetPasswordAction = (payload) => ({ type: "FORGET_PASSWORD", payload })
export const resetPasswordSuccess = () => ({ type: "RESET_PASSWORD_SUCCESS"})
export const resetPasswordFailed = (payload) => ({ type: "RESET_PASSWORD_FAILED", payload })
export const resetPasswordUpdateStatue = (payload) => ({ type: "RESET_PASSWORD_UPDATE_STATUE", payload})
export const resetPasswordClear = () => ({ type: "RESET_PASSWORD_CLEAR"})
export const setSelectHealthArea = (payload) => ({type: "SET_SELECTED_HEALTH_AREA", payload})

// methods
export const fetchUser = (userInfo) => dispatch => {

    dispatch(userIsLoadingAction());
    service.post('/authenticate', userInfo).then(async (res) => {

        localStorage.setItem("token", res.data.token);
        localStorage.setItem("employee", JSON.stringify(res.data.employee));
        setClientToken(res.data.token);
        dispatch(setUserAction(res.data.user));
    }).catch(e => {
        dispatch(errorLoginAction("Error occured while logging. Check your credintials or connection"));
    });
}

// export const autoLogin = () => dispatch => {
//     service.getRestClient().get('/employee/autoLogin').then( res => {
//         localStorage.setItem("token", res.data.token)
//         dispatch(setUserAction(res.data.user))
//     }).catch(e => {
//         console.log("error occured in autologin")
//     })
// }

// export const autoLogin = () => dispatch => {
//     const token = localStorage.getItem('token');

//     if (!token)
//         return;

//     const {name, iat} = jwt.decode(token);
//     alert(JSON.stringify(iat));
//     if (name &&  (iat * 1000 < Date.now())) {
//         console.log('valid')
//         dispatch(setUserAction(name));
//     }
//     else {
//         console.log('invalid');
//     }
// }

export const autoLogin = () => dispatch => {

    const token = localStorage.getItem('token');
    const employee = localStorage.getItem('employee');

    if (!employee || !token) {
        dispatch(logUserOutAction());
        return;
    }

    setClientToken(token);

    if (jwt.decode(token)) {
        const { sub } = jwt.decode(token);
        dispatch(setUserAction(sub));
    } else {
        dispatch(logoutUser());
    }
}

export const logoutUser = (userInfo) => dispatch => {
    localStorage.removeItem("token");
    localStorage.removeItem("employee");
    dispatch(logUserOutAction(userInfo))
    /*
    service.getRestClient().get('/logout').then( res => {
        dispatch(logUserOutAction(res.data.user))
    }).catch(e => {
        console.log("error occured in logout", e);
    })*/
}

export const forgetPassword = (userInfo) => dispatch => {

    dispatch(userIsLoadingAction());

    service.post('/user/forgetPassword/request', userInfo/*, {headers: {'Authorization': `Basic ${localStorage.getItem('token')}` }}*/).then(res => {
        dispatch(forgetPasswordAction("Please check your email for the instruction."))
    }).catch(e => {
        dispatch(forgetPasswordAction('Sorry, could not retrieve your password. Kindly contact the support team'));
    })
}

export const resetPassword = (values) => dispatch => {
    dispatch(userIsLoadingAction());

    service.post('/user/forgetPassword/update', values).then(res => {
        dispatch(resetPasswordSuccess());
    }).catch(e => {
        dispatch(resetPasswordFailed(e.response.data.response));
    })
}

export const updateCurrentPassword = (values) => dispatch => {
    dispatch(userIsLoadingAction());
    service.put('/user/password', {password: values.currentPassword, newPassword: values.password}).then(res => {
        dispatch(resetPasswordSuccess())
    }).catch(e => {
        dispatch(resetPasswordFailed('Sorry, could not set your new password.'));
    })
}

export const resetPasswordStatueUpdate = (statue) => dispatch => {
    dispatch(resetPasswordUpdateStatue(statue))
}
