import React from "react";

const FooterComponent = () => {

    let year = new Date().getFullYear();

    return (
        <footer className="footer-basic grey_bg">
            <div className="social">
                <a href="https://wa.me/+96524870122" target="_blank">
                    <i className="ion-social-whatsapp-outline"></i>
                </a>
                <a href="mailto:support@cdphc.com" target="_blank">
                    <i className="ion-ios-email-outline"></i>
                </a>
                <a href="https://instagram.com/cdphc_kw" target="_blank">
                    <i className="ion-social-instagram-outline"></i>
                </a>
            </div>
            <p className="copyright">
                Central Directorate of Primary Health Care&nbsp; © {year}
            </p>
        </footer>
    );
};

export default FooterComponent;
