import {Row} from "antd";
import React, {useEffect} from "react";
import NavComponent from "../common/nav";
import HeaderComponent from "../common/header";
import FooterComponent from "../common/footer";
import HomeComponent from "../common/Home";
import {useSelector} from "react-redux";
import {Redirect} from "react-router";
import {useTranslation} from "react-i18next";

export const HomePage = () => {

    const {t} = useTranslation();

    return (
        <>
            <NavComponent/>
            <HeaderComponent section="Guidelines"/>
            <section>
                <div className="mycontainer">
                    <h1
                        id={"home-title"}
                        className="hero_text hero_text_align_center"
                        style={{
                            marginBottom: "80px",
                            textAlign: "left",
                            fontFamily: "Montserrat, sans-serif",
                            fontWeight: 800,
                            fontOpticalSizing: "auto",
                            fontStyle: "normal",
                            textShadow: "0 0 5px #1c3564",
                        }}
                    >
                        {t("home.title")}
                    </h1>
                    <HomeComponent/>
                </div>
            </section>
            <FooterComponent/>
        </>
    )
}

export default HomePage;
